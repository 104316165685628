import React from 'react';
import { BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { FirebaseContext } from './Firebase';

// Componentes
import Loading from './Loading';
import Login from './Login/Login';
import MenuLateral from './MenuLateral';
import MenuSuperior from './MenuSuperior';
import FormPegatinas from './Pegatinas/FormPegatinas';
import Inicio from './Inicio/Inicio'; 
import Pegatinas from './Pegatinas/Pegatinas';
import Noticias from './Noticias/Noticias';
import Chats from './Chat/Chats';
import Kanban from './Kanban/Kanban';
import Perfiles from './Perfiles/Perfiles';
import Configuracion from './Configuracion/Configuracion';
import Niveles from './Niveles/Niveles';
import Premios from './Premios/Premios';
import { Notification_UsuariosError, Notification_UsuarioSaldo } from './Notification';
 
// Estilos
import '../estilos/App.scss';
import '../estilos/animate/animate.min.css';

class App extends React.Component {

	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;
	
	// ================================================================================================================================================================================================
	//	REACT
	// ================================================================================================================================================================================================

	constructor(props){ super(props);

        // Inicializamos el estado
		this.state = {
            // Esperando: Si estamos esperando algo, esta propiedad será un texto que lo explique. Si no estamos esperando nada, esto será una cadena vacía
            esperando: "Cargando App...",
            // Login: Cuando esperando sea una cadena vacía, hay dos opciones, o nos hemos logueado y en login tenemos al usuario, o tenemos que loguearnos y login será null
            login: null,
            // Usuarios: Lista completa de usuarios que nos viene bien tener para muchas cosas
            usuarios: [],
            // Formulario de pegatina
			formPegatinas: { abierto: false }
		}
        
        // Vinculamos los eventos con la instancia
        this.actualizarUsuarioLogueado = this.actualizarUsuarioLogueado.bind(this);
        this.FormPegatinas_Abrir = this.FormPegatinas_Abrir.bind(this);
        this.FormPegatinas_Cerrar = this.FormPegatinas_Cerrar.bind(this);

	}
	
	componentDidMount(){

		// Referencia a Firebase
		let api = this.context;
        
        // Ponemos un evento que se ejecute cada vez que cambie el estado de logueo del usuario
		api.onChangeUser(user => {

            // Si hay cambios de logueo, comprobamos si hay un usuario logueado y si es así, lo metemos en el State
			if(user) {

                // Lo que hemos recibido es un objeto User Account, que no tiene los datos del usuario, pero sí tiene su UID. Cambiamos el mensaje de espera
                this.setState({ esperando: "Cargando Datos..." });

                // Pedimos la lista completa de usuarios que nos vendrá bien para varias cosas, y de paso cogemos de ahí al usuario logueado
                api.getUsers(usuarios => {

                    // Ya podemos acceder a los datos del usuario logueado
                    let usuario = usuarios[user.uid];

                    // Indicamos que se acabó la espera, guardamos al usuario logueado y metemos la lista en el State. Después ejecutamos las acciones que sean necesarias al abrir la aplicación
                    this.setState({ esperando: "", login: usuario, usuarios: usuarios },()=>{ this.onAbrirAplicacion(); });

                },mensaje => {

                    // En caso de no haber podido obtener la lista de usuarios, abortamos el login mostrando un error
                    Notification_UsuariosError(mensaje);
                    api.doLogout();

                });

			} else {
				
				// Si NO hay un usuario logueado, borramos los posibles datos que pudiera haber de una sesión anterior
				this.setState({ esperando: "", login: null, usuarios: [] });
				
			}
		});
		
	}
	
	render() {

        // Si estamos esperando, mostramos la pantalla de loading pasándole el mensaje de espera
        if(this.state.esperando) return <Loading mensaje={this.state.esperando} />;

        // Si NO estamos esperando pero el usuario es NULL, mostramos la pantalla de login
        if(!this.state.esperando && !this.state.login) return <Login />;

        // Si NO estamos esperando y sí tenemos un usuario, mostramos la aplicación
        return (

					<Router>
						
						<MenuLateral usuario={this.state.login} pegatina={this.FormPegatinas_Abrir} />
						<MenuSuperior />

						<div id="App">
							<Switch>
								<Route exact path="/"><Inicio login={this.state.login} usuarios={this.state.usuarios} /></Route>
								<Route exact path="/pegatinas"><Pegatinas usuario={this.state.login} usuarios={this.state.usuarios} /></Route>
								<Route path="/noticias"><Noticias usuario={this.state.login} /></Route>
								<Route path="/chats"><Chats usuario={this.state.login} usuarios={this.state.usuarios} /></Route>
								<Route path="/kanban"><Kanban /></Route>
								<Route exact path="/configuracion"><Configuracion usuario={this.state.login} actualizar={this.actualizarUsuarioLogueado} /></Route>
								<Route exact path="/niveles"><Niveles login={this.state.login} /></Route>
								<Route exact path="/premios"><Premios login={this.state.login} /></Route>
								<Route path="/perfiles"><Perfiles usuario={this.state.login} usuarios={this.state.usuarios} /></Route>
							</Switch>
						</div>

						{this.state.formPegatinas.abierto && <FormPegatinas usuario={this.state.login} usuarios={this.state.usuarios} actualizar={this.actualizarUsuarioLogueado} cerrar={this.FormPegatinas_Cerrar} />}
						
					</Router>

        );
		
	}

	// ================================================================================================================================================================================================
	//	FUNCIONES
	// ================================================================================================================================================================================================

    // Aunque el usuario logueado se coge al pedir la lista usuarios, a veces hay que actualizar sus valores, por ejemplo, al cambiar la configuración
    // NOTA: Esta misma función sirve para cambiar el usuario logueado por otro usuario sin necesidad de hacer login, sólo hay que pasarle el UID
	actualizarUsuarioLogueado(uid){

        // Si no nos dan un uid para actualizar al usuario, usamos el actual
        if(!uid) uid = this.state.login.uid;

		// Referencia a Firebase
		let api = this.context;

		// Obtenemos los datos del usuario conectado
		api.getUser(uid, usuario => {
            // Actualizamos el usuario en la lista de usuarios y en el login del State
            let usuarios = this.state.usuarios;
            usuarios[uid] = usuario;
            // Ahora actualizamos el State
			this.setState({ esperando: "", login: usuario, usuarios: usuarios });
		});

    }
    
    // Acciones que se ejecutan al abrir la aplicación justo después de hacer el login
    onAbrirAplicacion(){

		// Referencia a Firebase
        let api = this.context;
        
        // Miramos si hay que actualizar el saldo de pegatinas del usuario logueado
        let usuario = this.state.login;

        // Cogemos la fecha del último saldo y la fecha actual para comparar
        let fechaSaldo = usuario.saldoPegatinas && usuario.saldoPegatinas.fecha? new Date(usuario.saldoPegatinas.fecha.seconds*1000) : new Date(0);
        let fechaActual = new Date();

        // Si la fecha ha cambiado, tenemos que rellenar el saldo del usuario
        if(fechaSaldo.toISOString().substr(0,10)!==fechaActual.toISOString().substr(0,10)){
            // Actualizamos el saldo del usuario y la fecha de actualización de saldo
            usuario.saldoPegatinas = {};
            usuario.saldoPegatinas.fecha = new Date();
            usuario.saldoPegatinas.puntos = 50;
            // Guardamos en la base de datos, también en el State y notificamos que se ha actualizado el saldo
            api.setUser(usuario.uid, usuario, () => {
                // Actualizamos el usuario en la lista de usuarios y en el login del State
                let usuarios = this.state.usuarios;
                usuarios[usuario.uid] = usuario;
                // Ahora actualizamos el State
                this.setState({ esperando: "", login: usuario, usuarios: usuarios });
                // Y mostramos una notificación
                Notification_UsuarioSaldo();
            });
        }

    }

	// ================================================================================================================================================================================================
	//	FORMULARIO PEGATINAS
	// ================================================================================================================================================================================================

    // Abre el formulario de dar pegatinas
	FormPegatinas_Abrir(objeto){
		// Abrimos el formulario con el objeto seleccionado (si está vacío, saldrá en blanco para crear uno nuevo)
		this.setState({ formPegatinas: { abierto: true } });
	}

    // Cierra el formulario de dar pegatinas
	FormPegatinas_Cerrar(huboCambios){
		// Cerramos el formulario y decidimos qué hacer en función de si hubo cambios o no
		this.setState({ formPegatinas: { abierto: false } });
		//if(huboCambios) this.TablaNombre_Actualizar();
	}
  
}

export default App;