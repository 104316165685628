import React from 'react';
import { FirebaseContext } from '../Firebase';
import { withRouter } from 'react-router-dom';

class PerfilEquipo extends React.Component {
		
	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;
	
	// ================================================================================================================================================================================================
	//	React
	// ================================================================================================================================================================================================


	constructor(props){ super(props);
		this.state = {
			uid: "",
			perfil: {}
		}
	}

	componentDidMount(){
		
		// Referencia a Firebase
		let api = this.context;

		// Cogemos el UID de la noticia que queremos editar ó la palabra "nueva" si es una noticia nueva
		const uid = this.props.location.pathname.replace("/perfiles/equipo/","");
		
		// Guardamos el UID de la noticia que queremos cargar
		this.setState({ uid: uid });

		// Solicitamos la noticia a la base de datos
		api.getPerfil(uid, datos => {
			this.setState({ perfil: datos });
		});

	}
	
	render(){
		
		// Cogemos el UID de la noticia que queremos cargar
		//const uid = this.props.location.pathname.replace("/noticias/","");

		// Imagen que se mostrará, si no hay imagen se pondrá la imagen por defecto
		//const imagen = this.state.noticia.imagen || "/img/noticia.jpg";

		return (
			<div id="PerfilEquipo" className="container">
				<div className="row">
					 
					<div className="col-12">
						<div className="componente">
							<h2>{this.state.perfil.nombre}</h2>
							<div className="imagen" style={{backgroundImage: "url(" + (this.state.perfil.imagen || "/img/perfil-" + this.state.perfil.uid + ".jpg") + ")"}}></div>
							<p>{this.state.perfil.descripcion}</p>
							<p></p>
						</div>
					</div>

				</div>
			</div>
		);
		
	}
	
}

export default withRouter(PerfilEquipo);