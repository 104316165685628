import React from 'react';
//import { store } from 'react-notifications-component';
import '../../estilos/kanban.scss';

class Kanban extends React.Component {

	componentDidMount(){
		
		window.$(function(){
			
			window.$("#Kanban").sortable({
				placeholder: "placeholder-lista"
			});
			
			
			window.$( "#tabla01, #tabla02, #tabla03" ).sortable({
				connectWith: ".conexion",
				placeholder: "placeholder-carta"});
		});
  
  
	}
	
	
	render(){
		return (
			<div id="Kanban"> 


<div className="lista">
	<div className="cabecera">To Do</div>
	<ul id="tabla01" className="conexion">
	  <li className="ui-state-default">Estimación Tiempo</li>
	  <li className="ui-state-default">Crear Base de Datos</li>
	  <li className="ui-state-default">Definir Variables</li>
	  <li className="ui-state-default">Alta Usuarios</li>
	  <li className="ui-state-default">Configurar App</li>
	</ul>
	<div className="pie">+ Añada otra tarjeta</div>
</div>


<div className="lista">
	<div className="cabecera">Doing</div>
	<ul id="tabla02" className="conexion">
	  <li className="ui-state-default">Diseño Web</li>
	  <li className="ui-state-default">Maquetación Home</li>
	</ul>
	<div className="pie">+ Añada otra tarjeta</div>
</div>
 

<div className="lista">
	<div className="cabecera">Done</div>
	<ul id="tabla03" className="conexion">
	  <li className="ui-state-default">Reunión con Cliente</li>
	  <li className="ui-state-default">Análisis de Requisitos</li>
	</ul>
	<div className="pie">+ Añada otra tarjeta</div>
</div>

			</div>
		);
	}
	
}

export default Kanban;
