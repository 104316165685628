import React from 'react';
import { FirebaseContext } from '../Firebase';
import { NavLink } from 'react-router-dom';

// Componentes
import PopupChats from './PopupChats';

class ListaChats extends React.Component {

	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;

	// ================================================================================================================================================================================================
	//	REACT
	// ================================================================================================================================================================================================

	constructor(props){ super(props);

		// Definimos el State inicial
		this.state = {
			esperando: "Cargando Chats...",
			// Lista de chats disponibles
			chats: [],
			// Datos referentes al formulario popup
			popupChats: { chat: null, abierto: false }
		};

        // Vinculamos los eventos con la instancia
		this.onClickNuevoChat = this.onClickNuevoChat.bind(this);
		this.PopupChats_Cerrar = this.PopupChats_Cerrar.bind(this);

	}

	componentDidMount(){

		// Al montar el componenete actualizamos la lista de chats
		this.ListaChats_Actualizar();

	}

	render(){

		if(this.state.esperando) return (<div>{this.state.esperando}</div>);
		
		return(
			<div id="ListaChats" className="container">
				<div className="componente">
					<div className="row">
						<div className="col-12">
							<button className="btn btn-primary float-right" onClick={this.onClickNuevoChat}>Nuevo Chat</button>
							<h3>Chats Disponibles</h3>
							<hr className="mt-4" />
							{this.Render_Chats()}
						</div>
					</div>

					

				</div>

				{this.state.popupChats.abierto && <PopupChats datos={this.state.popupChats} usuario={this.props.usuario} usuarios={this.props.usuarios} chats={this.state.chats} cerrar={this.PopupChats_Cerrar} />}
			
			</div>

		);
		
	}

	// ================================================================================================================================================================================================
	//	FUNCIONES
	// ================================================================================================================================================================================================

	Render_Chats(){

		// Lista completa de chats disponibles
		const chats = this.state.chats;

		// Recorremos los chats uno a uno
		return Object.keys(chats).map(uid => {

			const chat = chats[uid];

			// Si es de tipo individual, cogemos el nombre del usuario que no es el que está logueado
			const uid2 = chat.participantes.filter(participante => participante!==this.props.usuario.uid )[0];

			// Usuario2 es el usuario con el que estamos hablando
			const usuario2 = this.props.usuarios[uid2];

			return (<NavLink key={uid} exact to={"/chats/"+uid} >
						<div className="chat">
							<div className="foto" style={{backgroundImage: "url("+(usuario2.imagen || "/img/usuario.jpg")+")"}} alt={usuario2.nombre+" "+usuario2.apellidos}></div>
							<strong>{usuario2.nombre} {usuario2.apellidos}</strong>
							<small>{chat.fechaUltimo && this.parseFecha(chat.fechaUltimo.toDate())}</small>
							<i>{chat.textoUltimo? chat.textoUltimo : "No hay ningún mensaje"}</i>
						</div>	
					</NavLink>);
					
		})

	}
	ListaChats_Actualizar(){

		// Referencia a Firebase
		let api = this.context;

		// Solicitamos la lista de chats en los que participa el usuario logueado y la metemos en el State para que se actualice la lista
        api.getChats(this.props.usuario.uid,
            chats => { this.setState({ esperando:"", chats: chats }); }
        );

	}

    onClickNuevoChat(evento){ evento.preventDefault();
    
        // Abrimos el formulario pasandole un cero como UID, así aparecerá vacío para crear un grupo chat
        this.PopupChats_Abrir(0);
    
	}


    // ================================================================================================================================================================================================
    //	POPUP CHATS
    // ================================================================================================================================================================================================

    PopupChats_Abrir(uid){
        // Abrimos el popup con el uid del chat seleccionado (si el uid es cero, el formulario saldrá en blanco para crear un chat nuevo)
        this.setState({ popupChats: { uid: uid, abierto: true } });
    }
    
    PopupChats_Cerrar(huboCambios, chat){
        // Cerramos el popup y refrescamos la lista si hemos aceptado los cambios
        this.setState({ popupChats: { uid: null, abierto: false } });
        if(huboCambios) this.ListaChats_Actualizar();
        // Si nos han dado un UID de chat, nos pasamos a ese gchatrupo
        if(chat){
            this.setState({ chat: chat, mensajes: [] }, this.ListaMensajes_Actualizar );            
        }
	}
	
	// ================================================================================================================================================================================================
	//	FUNCIONES
	// ================================================================================================================================================================================================

	parseFecha(fecha){
		let txtFecha = ("00" + fecha.getDate()).slice(-2) + "/" + ("00" + (fecha.getMonth()+1)).slice(-2) + "/" + fecha.getFullYear();
		let txtHora = fecha.getHours() +  ":" + ("00"+fecha.getMinutes()).slice(-2) + ":" + ("00"+fecha.getSeconds()).slice(-2);
		if(new Date().toISOString().slice(0,10)===fecha.toISOString().slice(0,10)){
			return  txtHora ;
		}else {
			return  txtFecha + " " + txtHora ;
		}
		
	}
	
}

export default ListaChats;
