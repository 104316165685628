import React from 'react';
import { FirebaseContext } from '../Firebase';
import { NavLink } from 'react-router-dom';

class UltimasNoticias extends React.Component {

	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;
	
	// ================================================================================================================================================================================================
	//	REACT
	// ================================================================================================================================================================================================

	constructor(props){ super(props);

		// Inicializamos el estado
		this.state = { noticias: {} }

	}


	componentDidMount(){

		// Referencia a Firebase
		let api = this.context;

		// Obtenemos las últimas noticias
		// NOTA: estas son la que estén publicadas, con fechas más cercanas a la fecha actual, pero anterior a la fecha actual
		api.getUltimasNoticias(3,
			noticias => { this.setState({ noticias: noticias }); },
			error => { console.log(error); }
		);

	}

    render() {
		
		// Si no hay noticia nos vamos ya
		if(Object.keys(this.state.noticias).length===0) return null;

        return (
    
					<div id="UltimasNoticias" className="col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12">
						<div className="componente">
							<h3>Últimas noticias</h3>

							{Object.keys(this.state.noticias).map(uidNoticia => {
								let noticia = this.state.noticias[uidNoticia];
								let imagen = noticia.imagen || "/img/noticia.jpg";
								return (
									<NavLink key={noticia.uid} exact to={"/noticias/"+noticia.uid} className="noticia">
										<div className="foto" style={{backgroundImage: "url(" + imagen + ")"}}></div>
										<div>
											<h4 className="titulo">{noticia.titulo}</h4>
											<p>{noticia.descripcion}</p>
										</div>
									</NavLink>
								);
							})}


						</div>
					</div>

      
        );
    }
	
}

export default UltimasNoticias;
