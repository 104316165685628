import React from 'react';
import { FirebaseContext } from '../Firebase';
import { Notification_LoginOk, Notification_LoginError, Notification_ResetOk, Notification_ResetError } from '../Notification';

// Estilos
import '../../estilos/Login.scss' 

class Login extends React.Component {
	
	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;

	// ================================================================================================================================================================================================
	//	REACT
	// ================================================================================================================================================================================================

    constructor(props){ super(props);
        
        // Inicializamos el estado
		this.state = { comprobando: false, formulario:"login", email:"", password:"", mensaje: "" };
        
        // Enlazamos los eventos
        this.onChange = this.onChange.bind(this);
        this.onSubmitLogin = this.onSubmitLogin.bind(this);
        this.onSubmitReset = this.onSubmitReset.bind(this);
        this.onClickOlvidado = this.onClickOlvidado.bind(this);
        this.onClickCancelar = this.onClickCancelar.bind(this);
        
	}

	render(){
		
		return(
		
			<div id="Login" className="text-center">
			
				{this.state.formulario==="login"?
					<form className="login-formulario" onSubmit={this.onSubmitLogin}>

						<img className="mb-4 img-thumbnail" src="/img/logo.png" alt="logo" width="150" height="85" />
						<h2 className="h3 mb-3 font-weight-normal">Login</h2>
						<label htmlFor="txtEmail" className="sr-only">Dirección de Correo</label>
						<input type="text" id="txtEmail" className="form-control" value={this.state.email} onChange={this.onChange} placeholder="Dirección de correo" />
						<label htmlFor="txtPassword" className="sr-only">Password</label>
						<input type="password" id="txtPassword" className="form-control" value={this.state.password} onChange={this.onChange} placeholder="Contraseña" />
						
						<button className="btn btn-primary btn-block nofocus" type="submit" disabled={this.state.comprobando}>
						{this.state.comprobando && <span className="spinner-border spinner-border-sm" role="status" style={{width: "1.5rem", height: "1.5rem"}} aria-hidden="true"></span>}
						{this.state.comprobando? " Comprobando...":"Aceptar"}
						</button>

						<button className="btn btn-sm nofocus" type="button" disabled={this.state.comprobando} onClick={this.onClickOlvidado}>He olvidado mi contraseña</button>
						
                        {this.state.mensaje.length > 0 && (<div><br /><div className="alert alert-danger" role="alert">{this.state.mensaje}</div></div>)}

					</form>
				:
					<form className="login-formulario" onSubmit={this.onSubmitReset}>

						<img className="mb-4 img-thumbnail" src="/img/logo.png" alt="logo" width="150" height="85" />
						<h2 className="h3 mb-3 font-weight-normal">Recuperar Password</h2>
						<label htmlFor="txtEmail" className="sr-only">Dirección de Correo</label>
						<input type="text" id="txtEmail" className="form-control" value={this.state.email} onChange={this.onChange} placeholder="Dirección de correo" />
						
						<button className="btn btn-primary btn-block nofocus" type="submit" disabled={this.state.comprobando}>
						{this.state.comprobando && <span className="spinner-border spinner-border-sm" role="status" style={{width: "1.5rem", height: "1.5rem"}} aria-hidden="true"></span>}
                        {this.state.comprobando? " Comprobando...":"Restablecer Password"}
						</button>

						<button className="btn btn-sm nofocus" type="button" disabled={this.state.comprobando} onClick={this.onClickCancelar}>Cancelar</button>

                        {this.state.mensaje.length > 0 && (<div><br /><div className="alert alert-danger" role="alert">{this.state.mensaje}</div></div>)}

					</form>
				}
				
                

			</div>
		
		);
		
	}

	// ================================================================================================================================================================================================
	//	EVENTOS
	// ================================================================================================================================================================================================

    onChange(evento) {
        // Este evento sirve tanto para el formulario de Login como para el de Resetear Password
		if(evento.currentTarget.id==="txtEmail") { this.setState({email: evento.target.value, mensaje: ""}); }
		if(evento.currentTarget.id==="txtPassword") { this.setState({password: evento.target.value, mensaje: ""}); }
	}	
	
	onSubmitLogin(evento){ evento.preventDefault();
		
		// Validamos los datos
		if(this.state.email === "") { this.setState({mensaje:"Debe rellenar el campo Email"}); return; }
		if(this.state.password === "") { this.setState({mensaje:"Debe rellenar el campo Password"}); return; }
		
		// Si todo está ok, actualizamos "comprobando" a True
		this.setState({ comprobando: true });
		
		// Referencia a Firebase
		let api = this.context;
		
		// Preguntamos a la API si el login es correcto
        api.doLogin(this.state.email, this.state.password,
            usuarioId => {
                // Si el login se hizo bien, mostramos una notificación con el mail del usuario
                Notification_LoginOk(this.state.email);
                // NOTA: No tenemos que manejar nada más aquí. Desde App se detectará el cambio de usuario con onChangeUser
                },
            mensaje => {
                // Si hubo un error en el login, ponemos "comprobando" a False y mostramos una notificación con el error
                this.setState({ comprobando: false });
                Notification_LoginError(mensaje);
            }
		);
		
	}
	
	onSubmitReset(evento){ evento.preventDefault();
		
		// Validamos los datos
		if(this.state.email === "") { this.setState({mensaje:"Debe rellenar el campo Email"}); return; }
		
		// Si todo está ok, actualizamos "comprobando" a True
		this.setState({ comprobando: true });
		
		// Referencia a Firebase
		let api = this.context;

		// Pedimos a la API que envíe un mail de restablecimiento de password al email que nos han dado
        api.doReset(this.state.email,
            () => {
                // Si el envío se hizo bien, ponemos "comprobando" a False y mostramos una notificación con el mail del usuario
                this.setState({ comprobando: false });
                Notification_ResetOk(this.state.email);
            },
            mensaje => {
                // Si hubo un error en el envío, ponemos "comprobando" a Fasle y mostramos una notificación con el error
                Notification_ResetError(mensaje);
            });

	}

	onClickOlvidado(evento){evento.preventDefault(); this.setState({ formulario:"reset" }); }

	onClickCancelar(evento){evento.preventDefault(); this.setState({ formulario:"login" }); }

}

export default Login;
