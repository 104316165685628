import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';

// Componentes
import ListaChats from './ListaChats';
import Chat from './Chat';

// Estilos
import '../../estilos/chats.scss'


class Chats extends React.Component {

	// ================================================================================================================================================================================================
	//	REACT
	// ================================================================================================================================================================================================

	render(){
		
		return(
			<div id="Chats">
				<Switch>
					<Route exact path="/chats">
							
						<ListaChats usuario={this.props.usuario} usuarios={this.props.usuarios} />

					</Route>
					<Route exact path="/chats/:uid">
					
						<Chat usuario={this.props.usuario} usuarios={this.props.usuarios} />
					 
					</Route>
				</Switch>
			</div>
		);
		
	}

}

export default withRouter(Chats);
