import React from 'react';

// Estilos
import '../estilos/Loading.scss'


class Loading extends React.Component {
	
	// ================================================================================================================================================================================================
	//	REACT
	// ================================================================================================================================================================================================

	render(){
		
		return(
            <div id="Loading" className="text-center">     			
                <div className="spinner-border text-light" role="status"></div>
                <br />
                <span>{this.props.mensaje}</span>
		    </div>
		);
		
	}
	
}

export default Loading;