import React from 'react';


export function pegatinaPuntos(tipo){
    let puntos = 0;
    if(tipo==="bien") puntos = 20;
    if(tipo==="mal") puntos = -5;
    if(tipo==="animo") puntos = 10;
    if(tipo==="gracias") puntos = 25;
    //if(tipo==="muybien") puntos = 50;
    //if(tipo==="muymal") puntos = -20;
    return puntos;
}


export function pegatinaIcono(tipo){
    let clase = (tipo==="mal" || tipo==="muymal")? "negativo" : "positivo";
    let puntos = "";
    if(tipo==="bien") puntos = "+20";
    if(tipo==="mal") puntos = "-5";
    if(tipo==="animo") puntos = "+10";
    if(tipo==="gracias") puntos = "+25";
    //if(tipo==="muybien") puntos = "+50";
    //if(tipo==="muymal") puntos = "-20";
    return(
        <span className="pegatina">
            <img src={"./img/pegatinas/"+tipo+".gif"} alt={"pegatina-"+tipo} />
            <small className={clase}>{puntos}</small>
        </span>
        
    );
}

export function segundosXfecha(segundos,incluirHora){
    if(segundos===undefined) return "";
    const fecha = new Date(segundos*1000);
    let respuesta = ("00"+fecha.getDate()).slice(-2) + "/" + ("00"+(fecha.getMonth()+1)).slice(-2) + "/" + fecha.getFullYear();
    if(incluirHora) respuesta += " " + ("00"+(fecha.getHours()+1)).slice(-2) + ":" + ("00"+(fecha.getMinutes()+1)).slice(-2) + ":" + ("00"+(fecha.getSeconds()+1)).slice(-2);
    return respuesta;
}