import React from 'react';
import { FirebaseContext } from '../Firebase';
import { withRouter } from 'react-router-dom';

class PerfilUsuario extends React.Component {
		
	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;
	
	// ================================================================================================================================================================================================
	//	React
	// ================================================================================================================================================================================================


	constructor(props){ super(props);
		this.state = {
			uid: "",
			perfil: {}
		}
	}

	componentDidMount(){
		
		// Referencia a Firebase
		let api = this.context;

		// Cogemos el UID de la noticia que queremos editar ó la palabra "nueva" si es una noticia nueva
		const uid = this.props.location.pathname.replace("/perfiles/usuario/","");
		
		// Guardamos el UID de la noticia que queremos cargar
		this.setState({ uid: uid });

		// Solicitamos la noticia a la base de datos
		api.getUser(uid, datos => {
			this.setState({ perfil: datos });
		});

	}
	
	render(){
		
		// Cogemos el UID de la noticia que queremos cargar
		//const uid = this.props.location.pathname.replace("/noticias/","");

		// Imagen que se mostrará, si no hay imagen se pondrá la imagen por defecto
		//const imagen = this.state.noticia.imagen || "/img/noticia.jpg";
		const usuario = this.state.perfil;

		return (
			<div id="PerfilUsuario" className="container">
				<div className="row">
					 
					<div className="col-12">
						<div className="background" style={{backgroundImage: "url(/img/background-" + usuario.uid + ".jpg)"}}>
							<div className="imagen" style={{backgroundImage: "url(" + (usuario.imagen || "/img/usuario.jpg") + ")"}}></div>
						</div>
					</div>
					<div className="col-12">
						<div className="componente">
							<h2>{usuario.nombre} {usuario.apellidos}</h2>
							<p>{usuario.contrato && <span className="descripcion">En la empresa desde el {this.fetFecha(usuario.contrato.toDate())}</span>}</p>
							<div><strong>Equipo: </strong><span>{usuario.equipo}</span></div>
							<div><strong>Gustos y aficiones: </strong><span>Lorem ipsum dolor sit amet</span></div>
							<div><strong>Participación: </strong><span className="txtRojo">Nivel 2</span></div>
						</div>
					</div>
				</div>
			</div>
		);
		
	}

	fetFecha(fecha){
		let dia = fecha.getDate();
		let mes = fecha.getMonth()+1;
		let ano = fecha.getFullYear();
		return ""+("00"+dia).slice(-2)+"/"+("00"+mes).slice(-2)+"/"+ano;
	}
}

export default withRouter(PerfilUsuario);