import React from 'react';
import { FirebaseContext } from '../Firebase';

// Componentes
import Contador from './Contador';
import Cumples from './Cumples';
import Informacion from './Informacion';
import UltimasNoticias from './UltimasNoticias';

// Estilos
import '../../estilos/Inicio.scss'


class Inicio extends React.Component {
	
	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;

    // ================================================================================================================================================================================================
    //  REACT
    // ================================================================================================================================================================================================

    constructor(props){ super(props);
        
        // Inicializamos el State
		this.state = {
			// Informaciones mostradas
			informaciones: {}
        };
        
        // Vinculamos los eventos con la instancia
        this.Informaciones_Eliminar = this.Informaciones_Eliminar.bind(this);
	}

	componentDidMount(){

		// Actualizamos la sección de información
		this.Informaciones_Actualizar();

	}
	
	
	render(){
		
		// Array de UIDs de todas las informaciones para ordenar por fecha
		let uidInformaciones = Object.keys(this.state.informaciones);

		// Ordenamos la lista de UIDs según la fecha de su objeto información
		uidInformaciones.sort((a,b) => this.state.informaciones[a].fecha.seconds-this.state.informaciones[b].fecha.seconds);
		
		return(
			<div id="Inicio" className="container">
				<div className="row">

					<Contador titulo="Pegatinas" icono="fa-award" color="txtAzul" valor="32" />
					<Contador titulo="Positivas" icono="fa-heart" color="txtVerde" valor="25" />
					<Contador titulo="Negativas" icono="fa-angry" color="txtRojo" valor="3" />
					<Contador titulo="Especiales" icono="fa-medal" color="txtAmarillo" valor="2" />

					<Cumples usuarios={this.props.usuarios} />

					{uidInformaciones.map(informacion => 
						<Informacion key={informacion} login={this.props.login} informacion={this.state.informaciones[informacion]} eliminar={this.Informaciones_Eliminar} />
					)}
					
					<UltimasNoticias />

				</div>
			</div>
		);
		
	}

    // ================================================================================================================================================================================================
    //  INFORMACIONES
    // ================================================================================================================================================================================================

	Informaciones_Actualizar(){
		
		// Referencia a Firebase
		let api = this.context;
		
		// Obtenemos los registros de información del usuario logueado
		api.getInformaciones(this.props.login.uid, informaciones => {
			this.setState({ informaciones: informaciones }); 
		});
		
	}

	Informaciones_Eliminar(uid){

		// Referencia a Firebase
		let api = this.context;
		
		// Eliminamos el elemento y actualizamos
		api.delInformaciones(uid,() => {

	        // Ponemos en marcha la animación de salida
			window.document.getElementById("info"+uid).classList.add("fadeOutDown");
			// Las animaciones están en modo "faster", así que habría que esperar 500ms para que se complete, pero podemos actualizar antes
			window.setTimeout(() => { this.Informaciones_Actualizar(); },200);

		});
		
	}

}

export default Inicio;
