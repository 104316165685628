// Librerías
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FirebaseContext } from './Firebase';

// Estilos
import '../estilos/menusuperior.scss';

class MenuSuperior extends React.Component {

	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;
	
    render() {

        return (
    
			<div id="MenuSuperior">
				
				<ul className="menu-superior-opciones">
					<li className="solo-md-lg-xl">
						<NavLink type="button" className="btn nofocus" exact to="configuracion"><i className="fas fa-cog"></i></NavLink>
					</li>
					<li className="solo-md-lg-xl">
						<button type="button" className="btn nofocus" onClick={this.onClickLogout.bind(this)}><i className="fas fa-power-off"></i></button>
					</li>
					<li className="solo-xs-sm">
						<button type="button" className="btn nofocus" onClick={this.onClickMenu.bind(this)} ><i className="fas fa-ellipsis-v"></i></button>
					</li>
				</ul>
				
			</div>
      
        );

    }

	// Añade la clase "menu-abierto" para que se abra el MenuLateral
	onClickMenu(){ document.getElementById("root").classList.add("menu-abierto"); }
	
	onClickLogout(){

		// Referencia a Firebase
		let api = this.context;

		// Nos desconectamos
		api.doLogout();
		
	}
	
	
}

export default MenuSuperior;
