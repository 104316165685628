// Librerías
import React from 'react';
import { FirebaseContext } from '../Firebase';
import { Notification_ConfiguracionOk, Notification_ConfiguracionError, Notification_ConfiguracionImagenOk, Notification_ConfiguracionImagenError } from '../Notification';

// Estilos
import '../../estilos/Configuracion.scss';


class Configuracion extends React.Component {

	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;

	// Checkeo de campos de Formulario
	chkUsuario = {
		mod_lim: 50,
		mod_car: "1234567890abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ-_",
		chk_min: { valor: 4, mensaje: "Debe especificar un nombre de usuario" },
		chk_max: { valor: 15, mensaje: "El nombre de usuario no debe tener más de 15 caracteres" },
		chk_per: { valor: "1234567890abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZ-_", mensaje: "El nombre de usuario contiene caracteres NO permitidos" }
	};
	chkNombre = {
		mod_lim: 50,
		chk_min: { valor: 3, mensaje: "Debe especificar un nombre real" },
		chk_max: { valor: 30, mensaje: "El nombre real del usuario no puede tener más de 30 caracteres" }
	};
	chkApellidos = {
		mod_lim: 50,
		chk_max: { valor: 50, mensaje: "Los apellidos del usuario no pueden tener más de 50 caracteres" }
	};
	chkDia = {
		mod_lim: 2,
		mod_car: "1234567890",
		chk_sup: { valor: 31, mensaje: "El día del cumpleaños debe ser un número válido" },
		chk_inf: { valor: 1, mensaje: "El día del cumpleaños debe ser un número válido" },
		chk_per: { valor: "1234567890", mensaje: "El día del cumpleaños debe ser un número válido" }
	};

	// ================================================================================================================================================================================================
	//	React
	// ================================================================================================================================================================================================

    constructor(props){ super(props);
        
        // Inicializamos el State
        this.state = { usuario: props.usuario, porcentaje: 0 };

        // Referencias a elementos de Formulario
        this.refUsuario = React.createRef();
        this.refNombre = React.createRef();
        this.refApellidos = React.createRef();
        this.refProyecto = React.createRef();
        this.refArchivo = React.createRef();
        this.refDia = React.createRef();
        this.refMes = React.createRef();
        
        // Vinculamos los eventos a la instancia
        this.onClickAceptar = this.onClickAceptar.bind(this);
        this.onChangeFormulario = this.onChangeFormulario.bind(this);
        this.onChangeImagen = this.onChangeImagen.bind(this);

        this.onClickCancelarNotificaciones = this.onClickCancelarNotificaciones.bind(this);
        this.onClickActivarNotificaciones = this.onClickActivarNotificaciones.bind(this);
    
	}

	render(){
					
		// Imagen que se mostrará, si no hay imagen se pondrá la imagen por defecto
		const imagen = this.props.usuario.imagen || "/img/usuario.jpg";
		
		// Fecha de nacimiento del usuario, si la tiene puesta
		let mes = "01", dia = "1";
        if(this.props.usuario.cumple){
            mes = this.props.usuario.cumple.split("-")[0];
            dia = this.props.usuario.cumple.split("-")[1];
        }

        // Indica si se trata de una aplicación PWA instalada. Sólo queremos mostrar las notificaciones en aplicaciones instaladas
        const standalone = window.matchMedia('(display-mode: standalone)').matches;

		return (

			<div id="Configuracion" className="container componente">

				<h3>Configuración</h3>

				<div className="form-group row">

					<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-center">
						<div className="foto" style={{backgroundImage: "url("+imagen+")"}} alt="Foto Perfil"></div>
					</div>
							
					<div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
						<label htmlFor="filFoto">Subir Nueva Foto:</label>
						<input type="file" className="form-control-file nofocus" id="filFoto" ref={this.refArchivo} onChange={this.onChangeImagen} />
						<br />
						<div className="progress">
							<div className="progress-bar" role="progressbar" style={{width: this.state.porcentaje+"%"}}>{this.state.porcentaje} %</div>
						</div>
					</div>
					
				</div>
				
				<hr />
				
				<div className="form-group row">
					<label htmlFor="txtEmail" className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-form-label">Email:</label>
					<div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-4">
						<input type="text" className="form-control nofocus" id="txtEmail" ref={this.refEmail} defaultValue={this.state.usuario.email} readOnly={true} autoComplete="off" />
					</div>
					<small className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-6 text-muted">Dirección de correo del usuario.</small>
				</div>
				
				<div className="form-group row">
					<label htmlFor="txtUsuario" className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-form-label">Usuario:</label>
					<div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-4">
						<input type="text" className="form-control nofocus" id="txtUsuario" ref={this.refUsuario} defaultValue={this.state.usuario.usuario} onChange={this.onChangeFormulario} autoComplete="off" />
					</div>
					<small className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-6 text-muted">Nombre que se mostrará en la aplicación.</small>
				</div>

				<div className="form-group row">
					<label htmlFor="txtNombre" className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-form-label">Nombre:</label>
					<div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-4">
						<input type="text" className="form-control nofocus" id="txtNombre" ref={this.refNombre} defaultValue={this.state.usuario.nombre} onChange={this.onChangeFormulario} autoComplete="off" />
					</div>
					<small className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-6 text-muted">Nombre real del usuario.</small>
				</div>

				<div className="form-group row">
					<label htmlFor="txtApellidos" className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-form-label">Apellidos:</label>
					<div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-4">
						<input type="text" className="form-control nofocus" id="txtApellidos" ref={this.refApellidos} defaultValue={this.state.usuario.apellidos} onChange={this.onChangeFormulario} autoComplete="off" />	
					</div>
					<small className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-6 text-muted">Apellidos del usuario.</small>
				</div>

				<div className="form-group row">
					<label className="col-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 col-form-label">Cumpleaños:</label>
					<div className="col-4 col-sm-2 col-md-2 col-lg-2 col-xl-2">
						<input type="text" min="1" max="31" className="form-control nofocus" id="txtDia" ref={this.refDia} defaultValue={dia} onChange={this.onChangeFormulario} autoComplete="off" />	
					</div>
					<div className="col-8 col-sm-6 col-md-6 col-lg-4 col-xl-2">
						<select className="form-control nofocus" id="selMes" ref={this.refMes} defaultValue={mes} onChange={this.onChangeFormulario}>
							<option value="01">Enero</option>
							<option value="02">Febrero</option>
							<option value="03">Marzo</option>
							<option value="04">Abril</option>
							<option value="05">Mayo</option>
							<option value="06">Junio</option>
							<option value="07">Julio</option>
							<option value="08">Agosto</option>
							<option value="09">Septiembre</option>
							<option value="10">Octubre</option>
							<option value="11">Noviembre</option>
							<option value="12">Diciembre</option>
						</select>
					</div>
					<small className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-6 text-muted">Día y mes de nacimiento.</small>
				</div>

				<div className="form-group row">
					<label htmlFor="selProyecto" className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-form-label">Proyecto:</label>
					<div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-4">
						<select className="form-control nofocus" id="selProyecto" ref={this.refProyecto} defaultValue={this.props.usuario.proyecto} onChange={this.onChangeFormulario}>
							<option value="">Ninguno</option>
							<option value="Boletines">Boletines</option>
							<option value="Ilustradores">Ilustradores</option>
							<option value="Dynamics">General Dynamics</option>
						</select>
					</div> 
					<small className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-6 text-muted">Proyecto en el que participa el usuario.</small>
				</div>
				
				<div className="form-group row">
					<label htmlFor="txtPassword" className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-form-label">Password:</label>
					<div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-4">
						<button className="btn btn-warning btn-block" id="txtPassword">Solicitar cambio de Password</button>
					</div>
					<small className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-6 text-muted">Pulse aquí para cambiar su password.</small>
				</div>

				{ standalone && <div className="form-group row">
					<label htmlFor="txtNotificaciones" className="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2 col-form-label">Notificaciones:</label>
                    { this.state.usuario.token?
					    <div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-4">
                            <button className="btn btn-danger btn-block" id="txtNotificaciones" onClick={this.onClickCancelarNotificaciones} >Cancelar Notificaciones</button>
                            <input type="text" className="form-control mt-1" defaultValue={this.state.usuario.token} readOnly={true} />
                        </div>
                    :
                        <div className="col-12 col-sm-8 col-md-6 col-lg-6 col-xl-4">
                            <button className="btn btn-success btn-block" id="txtNotificaciones" onClick={this.onClickActivarNotificaciones} >Activar Notificaciones</button>
                        </div>
                    }
					<small className="col-12 col-sm-4 col-md-4 col-lg-4 col-xl-6 text-muted">Sólo podrá recibir notificaciones en un dispositivo.</small>
				</div>}

				<hr />
				
				<div className="row">
					<div className="col-12 text-center">
						<button className="btn btn-primary" onClick={this.onClickAceptar}>Guardar Cambios</button>
					</div>
				</div>

			</div>
		);
		
	}
	
    
	// ================================================================================================================================================================================================
	//	EVENTOS
	// ================================================================================================================================================================================================
    
    onClickCancelarNotificaciones(evento){evento.preventDefault();
        let usuario = this.state.usuario;
        usuario.token = "";
        this.setState({ usuario: usuario});
    }

    onClickActivarNotificaciones(evento){evento.preventDefault();
        
        // Referencia a Firebase
        let api = this.context;
        
        // Solicitamos el token
        api.allowMessaging(token => {
            let usuario = this.state.usuario;
            usuario.token = token;
            this.setState({ usuario: usuario});
        },error => { alert(error); });

    }

	onChangeImagen(evento){ evento.preventDefault();

		// Nombre del archivo que queremos subir
		var archivo = this.refArchivo.current.files[0];

		// Nos aseguramos de que sea un archivo JPG y si no lo es, mostramos un error y salimos
		if(archivo.name.split(".").pop().toUpperCase()!=="JPG" && archivo.name.split(".").pop().toUpperCase()!=="JPEG") {
            // Mostramos un error y salimos
            Notification_ConfiguracionImagenError("Sólo se permite el uso de archivos JPG");
			return;
		}
		
		// Referencia a Firebase
		let api = this.context;

		// Referencia a Storage
		var storage = api.storage.ref();
		
		// Subimos el archivo usando como nombre el uid del usuario para sobreescribir el antiguo. Indicamos que los metadatos son 'image/jpeg'
		var carga = storage.child('usuarios/' + this.props.usuario.uid +  '.jpg').put(archivo, { contentType: 'image/jpeg' });

		// Establecemos el evento 'state_changed' para saber cómo va y cuándo termina
		carga.on('state_changed', info => {

			// Cuando cambie el porcentaje de carga calculamos el valor y lo ponemos en el State
			let porcentaje = (info.bytesTransferred / info.totalBytes) * 100;
			this.setState({	porcentaje: porcentaje.toFixed(2) });

		}, (error) => {

			// Si hubo algún error, comprobamos cuál fue el problema
			let mensaje = "";
			switch(error.code) {
				case 'storage/unauthorized': mensaje = "No tiene permiso para acceder al objeto"; break;
				case 'storage/canceled': mensaje = "Se ha cancelado el proceso"; break;
				default: mensaje = "Error desconocido"; break;
			}

            // Lanzamos un error con la información
            Notification_ConfiguracionImagenError(mensaje);

		}, () => {

			// Si todo ha ido bien, cogemos la ruta de descarga y la guardamos en los datos del usuario
			carga.snapshot.ref.getDownloadURL().then((downloadURL) => {

				//let usuario = this.props.usuario;
				//usuario.imagen = downloadURL;
				this.setState({ usuario: {...this.state.usuario, imagen:downloadURL } });
				
				// Tenemos que actualizar el valor de la imagen en la base de datos ya, porque ya hemos sobreescrito la imagen antigua
				api.setUser(this.props.usuario.uid,{ imagen: downloadURL });
				
                // Lanzamos un error con la información
                Notification_ConfiguracionImagenOk();

				// Actualizamos los datos del usuario logueado
				this.props.actualizar(this.props.usuario.uid);

			});
		});

	}
	
	onClickAceptar(evento){ evento.preventDefault();

		// Referencia a Firebase
		let api = this.context;

		// Comprobamos que todo esté correcto, si no lo está la función checkFormulario lanzará los mensajes de error. Aquí sólo detenemos la función
		if(!this.checkFormulario()) return;
		
        // Guardamos el usuario por su UID
		api.setUser(this.state.usuario.uid,{

			usuario: this.refUsuario.current.value,
			nombre: this.refNombre.current.value,
			apellidos: this.refApellidos.current.value,
			proyecto: this.refProyecto.current.value,
            cumple: this.refMes.current.value + "-" + this.refDia.current.value,
            token: this.state.usuario.token || ""

		}, () => {

			// Mostramos un mensaje de que todo ha ido bien
			Notification_ConfiguracionOk();

			// Actualizamos los datos del usuario logueado
			this.props.actualizar(this.props.usuario.uid);

		});

	}

	// ================================================================================================================================================================================================
	//	CHECKEO DE FORMULARIO
	// ================================================================================================================================================================================================

	onChangeFormulario(evento){ evento.preventDefault();

		// Aplicamos las modificaciones que haya que hacer durante el evento OnChange
		this.modForm(this.refUsuario.current,this.chkUsuario);
		this.modForm(this.refNombre.current,this.chkNombre);
		this.modForm(this.refApellidos.current,this.chkApellidos);
		this.modForm(this.refDia.current,this.chkDia);

		// Podemos aplicar también los checkeos si queremos poner la clase "is-invalid" a los campos con errores sobre la marcha
		this.chkForm(this.refUsuario.current,this.chkUsuario,"is-invalid");
		this.chkForm(this.refNombre.current,this.chkNombre,"is-invalid");
		this.chkForm(this.refApellidos.current,this.chkApellidos,"is-invalid");
		this.chkForm(this.refDia.current,this.chkDia,"is-invalid");

    };
    
	checkFormulario(){

		// Checkeamos los campos y metemos en un Array todos los errores que nos vayan devolviendo las funciones chkForm
		let errores = [];
		errores.push(...this.chkForm(this.refUsuario.current,this.chkUsuario,"is-invalid"));
		errores.push(...this.chkForm(this.refNombre.current,this.chkNombre,"is-invalid"));
		errores.push(...this.chkForm(this.refApellidos.current,this.chkApellidos,"is-invalid"));
		errores.push(...this.chkForm(this.refDia.current,this.chkDia,"is-invalid"));
		
		// Mostramos todos los mensajes de error
		errores.forEach(mensaje => { Notification_ConfiguracionError(mensaje); } );

		// Devolvemos True si no hay ningún error en el Array
		return errores.length===0;
	}


	modForm(elemento,chkDatos){
		// Comprobamos si hay que limitar el número de caracteres
		if(chkDatos.mod_lim) elemento.value = elemento.value.substring(0,chkDatos.mod_lim);
		// Comprobamos si hay una lista de caracteres permitidos
		if(chkDatos.mod_car) elemento.value = elemento.value.split("").filter( c => chkDatos.mod_car.indexOf(c)>=0 ).join("");
	}

	chkForm(elemento,chkDatos,classError){
		let errores = [];

		// Comprobamos si hay que rellenar un mínimo de caracteres
		if(chkDatos.chk_min && elemento.value.length<chkDatos.chk_min.valor) errores.push(chkDatos.chk_min.mensaje);
		// Comprobamos si no se puede superar un máximo de caracteres
		if(chkDatos.chk_max && elemento.value.length>chkDatos.chk_max.valor) errores.push(chkDatos.chk_max.mensaje);
		// Comprobamos si se ha usado algún caracter que no esté en la lista de permitidos
		if(chkDatos.chk_per && elemento.value !== elemento.value.split("").filter( c => chkDatos.chk_per.valor.indexOf(c)>=0 ).join("")) errores.push(chkDatos.chk_per.mensaje);
		// Comprobamos si hay un valor numérico inferior
		if(chkDatos.chk_inf && +elemento.value<chkDatos.chk_inf.valor) errores.push(chkDatos.chk_inf.mensaje);
		// Comprobamos si hay un valor numérico superior
		if(chkDatos.chk_sup && +elemento.value>chkDatos.chk_sup.valor) errores.push(chkDatos.chk_sup.mensaje);

		// Si hemos detectado algún error en este elemento, le añadimos la classError indicada
		if(errores.length>0) { elemento.classList.add(classError); } else { elemento.classList.remove(classError); }

		// Devolvemos los errores
		return errores;
	}

}

export default Configuracion;