import React from 'react';
import ReactDOM from 'react-dom';
import Firebase, { FirebaseContext } from './componentes/Firebase';
import ReactNotification from 'react-notifications-component';
import * as serviceWorker from './serviceWorker';

import App from './componentes/App';

import './index.scss';
import './estilos/notification/notification.scss';


// ====================================================================================================================================================================================================
//  REACT
// ====================================================================================================================================================================================================

ReactDOM.render(
    <React.StrictMode>
        <FirebaseContext.Provider value={new Firebase()}>
            <ReactNotification />
            <App />
        </FirebaseContext.Provider>
    </React.StrictMode>,
    document.getElementById('root')
);


// Activamos el serviceWorker
serviceWorker.register();

// mmfernandez@gmail.com - ct1234
// dionisio.suarez@ctingenieros.es - ct1234
// pmoreno@ctingenieros.es - ct1234