// Librerías
import React from 'react';
import { NavLink } from 'react-router-dom';


class ListaPerfiles extends React.Component {

	render(){
		
		const perfiles = this.props.datos.lista;
		const usuarios = this.props.usuarios;

		return(
			<div id="ListaPerfiles" className="container">
				<div className="componente">
					<div className="row">
						<div className="col-12">
							<h3>Perfiles de Equipo</h3>
							{Object.keys(perfiles).map(uid => {
								let perfil = perfiles[uid];
								return (
									<NavLink key={uid} exact to={"/perfiles/equipo/"+uid}>
										<div className="row perfil">
										
											<div className="col-12 col-lg-4 p-3">
												<div className="imagen" style={{backgroundImage: "url(" + (perfil.imagen || "/img/perfil-" + uid + ".jpg") + ")"}}></div>
											</div>
											<div className="col-12 col-lg-8 p-3">
												<span className="nombre">{perfil.nombre}</span>
												<span className="descripcion">{perfil.descripcion}</span>	
											</div>
										</div>					
									</NavLink>
								);
							})}
						</div>
					</div>
				</div>
				<div className="componente">
					<div className="row">
						<div className="col-12">
							<h3>Perfiles de Usuarios</h3>
							{Object.keys(usuarios).map(uid => {
								let usuario = usuarios[uid];
								return (
									<NavLink key={uid} exact to={"/perfiles/usuario/"+uid}>
										<div className="row usuario">
										
											<div className="col-12 col-lg-2 p-3">
												<div className="imagen" style={{backgroundImage: "url(" + (usuario.imagen || "/img/usuario.jpg") + ")"}}></div>
											</div>
											<div className="col-12 col-lg-10 p-3">
												<span className="nombre">Usuario: <span className="txtAzul">{usuario.usuario}</span></span>
												<span className="descripcion">{usuario.nombre} {usuario.apellidos}</span>
												<span className="descripcion"><strong>Equipo: </strong><span>{usuario.equipo}</span></span>
												<span className="descripcion"><strong>Participación: </strong><span className="txtRojo">Nivel {"1234".charAt(Math.random()*"1234".length)}</span></span>
												
												{usuario.contrato && <span className="descripcion">En la empresa desde el {this.fetFecha(usuario.contrato.toDate())}</span>}
											</div>
										</div>
									</NavLink>
								);
							})}
						</div>
					</div>
				</div>
			</div>
		);
		
	}



	fetFecha(fecha){
		let dia = fecha.getDate();
		let mes = fecha.getMonth()+1;
		let ano = fecha.getFullYear();
		return ""+("00"+dia).slice(-2)+"/"+("00"+mes).slice(-2)+"/"+ano;
	}

}

export default ListaPerfiles;
/*

					{Object.keys(noticias).map(uid =>
						<NavLink key={uid} exact to={"/noticias/"+uid} className="noticia">
							<div className="row noticia">
							
								<div className="col-12 col-lg-4 p-3">
									<div className="imagen" style={{backgroundImage: "url(" + (noticias[uid].imagen || "/img/noticia.jpg") + ")"}}></div>
								</div>
								<div className="col-12 col-lg-8 p-3">
										<h4>{noticias[uid].titulo}</h4>
										<small>{this.parseFechaDMA(noticias[uid].fecha)}</small>
										<p>{noticias[uid].descripcion}</p>
								</div>
							</div>					
						</NavLink>	
					)}
*/