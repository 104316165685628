import React, {Component} from 'react';

class Contador extends Component {

    render() {
		
		let titulo = this.props.titulo;
		let subtitulo = ["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"][new Date().getMonth()] + " " + (new Date().getFullYear());
		let valor = this.props.valor;
		let color = this.props.color;
		let icono = this.props.icono;

        return (
    
			<React.Fragment>

				<div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-2">
					<div className="contador componente">
						<div className={"icono "+color}><i className={"fas fa-2x "+icono}></i></div>
						<div className="texto">
							<h3>{titulo}</h3>
							<small>{subtitulo}</small>
							<div className={color}>{valor}</div>
						</div>
					</div>
				</div>

			</React.Fragment>
      
        );

    }

}

export default Contador;
