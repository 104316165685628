import React from 'react';
import Chart from 'chart.js';


class Evolucion extends React.Component {

	grafico = null;
	
	// ================================================================================================================================================================================================
	//	REACT
	// ================================================================================================================================================================================================

    constructor(props) { super(props);

        // Mes y Año actual
		let mes = new Date().getMonth();
		let ano = new Date().getFullYear();
		
		// Número de días que tiene el mes actual
        let dias = new Date(ano, mes+1, 0).getDate();
        
        // Inicializamos el State
        this.state = { mes: mes, ano: ano, dias: dias, puntos: [] };
        
        // Cogemos referencia a elementos del DOM
        this.refEvolucion = React.createRef();

        // Vinculamos los eventos con la instancia
        this.onClickActualizar = this.onClickActualizar.bind(this);

	}

    // Inicializaremos el gráfico con datos vacíos, después añadiremos los datos desde la función ActualizarGráfico()
	componentDidMount() {

		// Generamos las etiquetas para la gráfica, que serán los días del mes
		let etiquetas = [];
		for(let i=1, ii=this.state.dias; i<=ii; i++) etiquetas.push(i);
		
		// Referencia al Canvas donde pondremos la gráfica
		const refEvolucion = this.refEvolucion.current;
		this.grafico = new Chart(refEvolucion, {
			type: "bar", 
			data: { labels: etiquetas },
			options: {
				scales: {
					yAxes: [{
						ticks: {
							beginAtZero: false
						}
					}]
				}
			}
        });
        
        // Actualizamos el gráfico para el usuario logueado
        this.actualizarGrafico(this.props.usuario.uid);
        
	}
  
    render() {
        
        return (
    
				<div className="col-12">
					<div className="Evolucion componente">
						<h3>
                            Evolución de Puntos:
                            <button type="button" className="btn btn-light btn-sm nofocus" ver="queridos" onClick={this.onClickActualizar}>Actualizar</button>
                        </h3>

						<canvas style={{ width: 800, height: 300 }} ref={this.refEvolucion} />

					</div>
				</div>
	  
        );
    }

    // ================================================================================================================================================================================================
	//	EVENTOS
	// ================================================================================================================================================================================================

    onClickActualizar(evento){ evento.preventDefault();
        this.actualizarGrafico(this.props.usuario.uid);
    }

    // ================================================================================================================================================================================================
	//	FUNCIONES
	// ================================================================================================================================================================================================

	actualizarGrafico(uidUsuario){ 

		if(this.grafico) {

            // Lista de pegatinas y de las UIDs de las pegatinas
            let pegatinas = this.props.pegatinas;
            let uids = Object.keys(pegatinas);

            // Recorreremos las pegatinas sumando por días y filtrando por el usuario logueado
			let puntosUsuario = [];
			
			for(let i=0, ii=this.state.dias; i<ii; i++){

				// Usamos un Reduce recorriendo los UIDs, para hacer un sumatorio que comience en cero
				let total = uids.reduce((suma,uid) => {
                    // Aquí recibimos la suma parcial, y el uid del elemento que estamos mirando
                    
					// Sumaremos sólo si la pegatina está destinada al usuario logueado
                    let puntos = 0;
                    
					// Si la pegatina va dirigida al usuario logueado y pertenece al día que estamos calculando, asignamos los puntos correspondientes
					if(uidUsuario===pegatinas[uid].para && new Date(pegatinas[uid].fecha.seconds*1000).getDate()===(i+1)) puntos = pegatinas[uid].puntos;
					
					// Añadimos los puntos que hemos calculado a la suma total
                    return(suma + puntos);
                    
                },0);
                
				// Metemos el total en el array de puntosUsuario
                puntosUsuario.push(total);
                
            }

			// Hacemos la suma acumulada de puntos por día
			let acumuladoUsuario = [];
			puntosUsuario.reduce((acumulador,actual,indice) => { acumuladoUsuario[indice]=acumulador+actual; return(acumulador+actual); },0);
			
            //this.grafico.options.title = {display: true, text: this.props.titulo};
			this.grafico.data = {
				labels: this.grafico.data.labels,
				datasets: [{
					type: "bar",
					label: "Puntos del día",
					backgroundColor: '#9BA9BB80',
					borderColor: '#9BA9BB',
					data: puntosUsuario
				},{
					type: "line",
					label: "Puntos Acumulados",
					fill: false,
					borderColor: '#F27461',
					data: acumuladoUsuario
				}]
			};
			this.grafico.update();
		}

		
	}
	
	
}

export default Evolucion;
