import React from 'react';
import { FirebaseContext } from '../Firebase';
import { Notification_PegatinaOk, Notification_PegatinaAviso, Notification_PegatinaError } from '../Notification.js';

// Estilos
import '../../estilos/Formpegatinas.scss';


class FormPegatinas extends React.Component {

	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;

	// Referencias
	refComentario = React.createRef();

    // Efecto generado al entregar la pegatina
    efecto = { objetos:[], handler: null, tiempo: 0, tiempo_Max: 20, velocidad_Max: 50, gravedad: 2, rozamiento: 0.8 };

	// ================================================================================================================================================================================================
	//	REACT
	// ================================================================================================================================================================================================

    constructor(props){ super(props);
        
        // Inicializamos el State
		this.state = {
            verPuntos: this.props.usuario.saldoPegatinas.puntos>=5?"5":"0",
            verUsuarios: "todos",
			pegatina_codigo: null,
			pegatina_puntos: null,
			usuario_uid: null
        }
        
        // Vinculamos los eventos con la instancia
        this.onClickCancelar = this.onClickCancelar.bind(this);
        this.onKeyUpEnviar = this.onKeyUpEnviar.bind(this);
        this.onClickEnviar = this.onClickEnviar.bind(this);
        this.onClickVerPuntos = this.onClickVerPuntos.bind(this);
        this.onClickVerUsuarios = this.onClickVerUsuarios.bind(this);

	}

	componentDidMount(){

		// Metemos los usuarios en el State
		this.setState({usuarios: this.props.usuarios});

		// Ponemos en marcha el drag-drop de jQuery UI
		window.$(".pegatina").draggable({
			revert: true,
			revertDuration: 200,
			start: function() {
				window.$(".usuario").droppable({
					drop: function( event, ui ) {
						let usuario_uid = event.target.getAttribute("uid");
						let pegatina_codigo = event.toElement.getAttribute("codigo");
						let pegatina_puntos = event.toElement.getAttribute("puntos");
						// Ya sabemos la pegatina elegida y al usuario al que va dirigida, lo metemos en el state
						this.setState({ usuario_uid: usuario_uid, pegatina_codigo: pegatina_codigo, pegatina_puntos: pegatina_puntos });
						// Ponemos el focus en el input del comentario
						this.refComentario.current.focus();
                        // Ponemos en marcha la animación del efecto
                        this.iniciarEfecto(event.clientX,event.clientY);

					}.bind(this)
				});
			}.bind(this)
		});
		
	}
	
    render() {
        
        // Array de los UIDs de todos los usuarios
        let uids = Object.keys(this.props.usuarios);

        // Lista de usuarios disponibles
		let usuarios = [];
        uids.forEach(uid => {
            if(this.state.verUsuarios==="todos" || this.props.usuarios[uid].proyecto === this.props.usuario.proyecto ) usuarios.push(this.props.usuarios[uid]);
        });

        // Saldo actual del usuario
        let saldo = this.props.usuario.saldoPegatinas.puntos;

		// Quitamos al propio usuario de la lista, para que no pueda darse pegatinas a él mismo
		//delete usuarios[this.props.usuario.uid];

        return (

			<div id="FormPegatinas" className="modal animated fadeIn fastest">
				<div className="modal-dialog modal-lg">
					<div id="FormPegatinasContenido" className="modal-content animated slideInDown fastest">



						<div className="modal-header">
							<button className="btn btn-blanco nofocus cerrar" onClick={this.onClickCancelar}><i className="fas fa-times-circle fa-2x txtRojo"></i></button>
							<h5 className="modal-title">Enviar Pegatina <span className="saldo">Saldo: {this.props.usuario.saldoPegatinas.puntos} puntos</span></h5>
						</div>
						
						<div className="modal-body">

							<div className="listaUsuarios">
								<h3>
                                Usuarios:
                                    <button className={"btn btn-light btn-sm nofocus "+(this.state.verUsuarios==="todos"?"active":"")} usuarios="todos" onClick={this.onClickVerUsuarios}>Ver todos</button>
                                    <button className={"btn btn-light btn-sm nofocus "+(this.state.verUsuarios==="grupo"?"active":"")} usuarios="grupo" onClick={this.onClickVerUsuarios}>Ver sólo mi equipo</button>
                                </h3>
								<div className="usuarios">
									{usuarios.map(usuario =>
										<div className={"usuario "+(usuario.uid===this.state.usuario_uid?"seleccionado":"")} key={usuario.uid} uid={usuario.uid}>
											<div className="foto" style={{backgroundImage: "url("+(usuario.imagen || "/img/usuario.jpg")+")"}} alt={usuario.nombre+" "+usuario.apellidos}></div>
											<small>{usuario.usuario}</small>
										</div>
									)}
								</div>
							</div>


							{this.state.pegatina_codigo?

								<div className="mensaje">
                                    <h3>Mensaje para {this.props.usuarios[this.state.usuario_uid].usuario}:</h3>
                                    <div className="input-group">
                                        <input type="text" className="form-control nofocus" ref={this.refComentario} onKeyUp={this.onKeyUpEnviar} placeholder="Escribe un comentario..." />
                                        <div className="input-group-append">
                                            <button className="btn btn-primary nofocus" onClick={this.onClickEnviar}>Enviar</button>
                                        </div>
                                    </div>
								</div>

							:
								<div className="listaPegatinas">
									<h3>
										Valor:
										{saldo>=25 && <button className={"btn btn-light btn-sm nofocus "+(this.state.verPuntos==="25"?"active":"")} puntos="25" onClick={this.onClickVerPuntos}>25</button>}
										{saldo>=20 && <button className={"btn btn-light btn-sm nofocus "+(this.state.verPuntos==="20"?"active":"")} puntos="20" onClick={this.onClickVerPuntos}>20</button>}
										{saldo>=15 && <button className={"btn btn-light btn-sm nofocus "+(this.state.verPuntos==="15"?"active":"")} puntos="15" onClick={this.onClickVerPuntos}>15</button>}
										{saldo>=10 && <button className={"btn btn-light btn-sm nofocus "+(this.state.verPuntos==="10"?"active":"")} puntos="10" onClick={this.onClickVerPuntos}>10</button>}
										{saldo>=5 && <button className={"btn btn-light btn-sm nofocus "+(this.state.verPuntos==="5"?"active":"")} puntos="5" onClick={this.onClickVerPuntos}>5</button>}
                                        {saldo>=5 && <button className={"btn btn-light btn-sm nofocus txtRojo "+(this.state.verPuntos==="-5"?"active":"")} puntos="-5" onClick={this.onClickVerPuntos}>-5</button>}
                                        {saldo<5 && <div className="text-center">No tienes saldo para enviar pegatinas<br />Mañana tendrás otros 50 puntos</div>}
									</h3>

									<div className={"pegatinas "+(this.state.verPuntos==="25"?"visibles":"ocultas")}>
										<img className="pegatina" codigo="1f3c5" puntos="25" src="/img/twemoji/1f3c5.png" alt="1f3c5" />
										<img className="pegatina" codigo="1f3c6" puntos="25" src="/img/twemoji/1f3c6.png" alt="1f3c6" />
										<img className="pegatina" codigo="1f4af" puntos="25" src="/img/twemoji/1f4af.png" alt="1f4af" />
										<img className="pegatina" codigo="1f48e" puntos="25" src="/img/twemoji/1f48e.png" alt="1f48e" />
										<img className="pegatina" codigo="1f396" puntos="25" src="/img/twemoji/1f396.png" alt="1f396" />
										<img className="pegatina" codigo="1f947" puntos="25" src="/img/twemoji/1f947.png" alt="1f947" />
									</div>

									<div className={"pegatinas "+(this.state.verPuntos==="20"?"visibles":"ocultas")}>
										<img className="pegatina" codigo="1f60a" puntos="20" src="/img/twemoji/1f60a.png" alt="1f60a" />
										<img className="pegatina" codigo="1f60d" puntos="20" src="/img/twemoji/1f60d.png" alt="1f60d" />
										<img className="pegatina" codigo="1f600" puntos="20" src="/img/twemoji/1f600.png" alt="1f600" />
										<img className="pegatina" codigo="1f618" puntos="20" src="/img/twemoji/1f618.png" alt="1f618" />
										<img className="pegatina" codigo="1f602" puntos="20" src="/img/twemoji/1f602.png" alt="1f602" />
										<img className="pegatina" codigo="1f609" puntos="20" src="/img/twemoji/1f609.png" alt="1f609" />
									</div>

									<div className={"pegatinas "+(this.state.verPuntos==="15"?"visibles":"ocultas")}>
										<img className="pegatina" codigo="1f918" puntos="15" src="/img/twemoji/1f918.png" alt="1f918" />
										<img className="pegatina" codigo="1f3cb" puntos="15" src="/img/twemoji/1f3cb.png" alt="1f3cb" />
										<img className="pegatina" codigo="1f4aa" puntos="15" src="/img/twemoji/1f4aa.png" alt="1f4aa" />
										<img className="pegatina" codigo="1f4ab" puntos="15" src="/img/twemoji/1f4ab.png" alt="1f4ab" />
										<img className="pegatina" codigo="1f4c8" puntos="15" src="/img/twemoji/1f4c8.png" alt="1f4c8" />
										<img className="pegatina" codigo="1f44d" puntos="15" src="/img/twemoji/1f44d.png" alt="1f44d" />
									</div>

									<div className={"pegatinas "+(this.state.verPuntos==="10"?"visibles":"ocultas")}>
										<img className="pegatina" codigo="1f3a3" puntos="10" src="/img/twemoji/1f3a3.png" alt="1f3a3" />
										<img className="pegatina" codigo="1f3b8" puntos="10" src="/img/twemoji/1f3b8.png" alt="1f3b8" />
										<img className="pegatina" codigo="1f3c4" puntos="10" src="/img/twemoji/1f3c4.png" alt="1f3c4" />
										<img className="pegatina" codigo="1f3b2" puntos="10" src="/img/twemoji/1f3b2.png" alt="1f3b2" />
										<img className="pegatina" codigo="1f4a3" puntos="10" src="/img/twemoji/1f4a3.png" alt="1f4a3" />
										<img className="pegatina" codigo="1f37a" puntos="10" src="/img/twemoji/1f37a.png" alt="1f37a" />
									</div>

									<div className={"pegatinas "+(this.state.verPuntos==="5"?"visibles":"ocultas")}>
										<img className="pegatina" codigo="1f41e" puntos="5" src="/img/twemoji/1f41e.png" alt="1f41e" />
										<img className="pegatina" codigo="1f43b" puntos="5" src="/img/twemoji/1f43b.png" alt="1f43b" />
										<img className="pegatina" codigo="1f42f" puntos="5" src="/img/twemoji/1f42f.png" alt="1f42f" />
										<img className="pegatina" codigo="1f40c" puntos="5" src="/img/twemoji/1f40c.png" alt="1f40c" />
										<img className="pegatina" codigo="1f419" puntos="5" src="/img/twemoji/1f419.png" alt="1f419" />
										<img className="pegatina" codigo="1f435" puntos="5" src="/img/twemoji/1f435.png" alt="1f435" />
									</div>

									<div className={"pegatinas "+(this.state.verPuntos==="-5"?"visibles":"ocultas")}>
										<img className="pegatina" codigo="1f621" puntos="-5" src="/img/twemoji/1f621.png" alt="1f621" />
										<img className="pegatina" codigo="1f480" puntos="-5" src="/img/twemoji/1f480.png" alt="1f480" />
										<img className="pegatina" codigo="26d4" puntos="-5" src="/img/twemoji/26d4.png" alt="26d4" />
										<img className="pegatina" codigo="1f4a9" puntos="-5" src="/img/twemoji/1f4a9.png" alt="1f4a9" />
										<img className="pegatina" codigo="1f9a0" puntos="-5" src="/img/twemoji/1f9a0.png" alt="1f9a0" />
										<img className="pegatina" codigo="1f61f" puntos="-5" src="/img/twemoji/1f61f.png" alt="1f61f" />
									</div>

								</div>
							}


						</div>

					</div>
				</div>
			</div>

        );
    }
	
	// ================================================================================================================================================================================================
	//	EVENTOS
	// ================================================================================================================================================================================================

	onClickVerPuntos(evento){ evento.preventDefault();
		let verPuntos = evento.currentTarget.getAttribute("puntos");
		this.setState({ verPuntos: verPuntos });
	}

	onClickVerUsuarios(evento){ evento.preventDefault();
		let verUsuarios = evento.currentTarget.getAttribute("usuarios");
		this.setState({ verUsuarios: verUsuarios });
    }
    
    // Si pulsamos enter, se redirige a un evento onClickEnviar
	onKeyUpEnviar(evento){ if(evento.key==="Enter") this.onClickEnviar(evento); }

    // Al hacer click en enviar, creamos un objeto pegatina y lo metemos en la base de datos
	onClickEnviar(evento){ evento.preventDefault();
		
		// Referencia a la base de datos
		let api = this.context;

		// Guardamos la pegatina en la base de datos
		if(this.state.pegatina_codigo!=="" && this.state.pegatina_puntos!=="" && this.refComentario.current.value!=="" && this.state.usuario_uid!==""){
			api.newPegatina({
				de: this.props.usuario.uid,
				para: this.state.usuario_uid,
				codigo: this.state.pegatina_codigo,
				puntos: +this.state.pegatina_puntos || 0,
				comentario: this.refComentario.current.value,
				fecha: new Date()
			}, usuarios => {
                // Lanzamos una notificación de éxito con el nombre del usuario que la recibirá
                Notification_PegatinaOk(this.props.usuarios[this.state.usuario_uid].usuario);
                // Quitamos el saldo correspondiente a la pegatina
                let usuarioLogueado = this.props.usuario;
                usuarioLogueado.saldoPegatinas.puntos -= Math.abs(this.state.pegatina_puntos);
                api.setUser(usuarioLogueado.uid, usuarioLogueado,()=>{
                    // Actualizamos al usuario logueado
                    this.props.actualizar(); }
                );
                // Lanzamos una PUSH al usuario receptor
                if(this.props.usuarios[this.state.usuario_uid].token){
                    // Destinatario, Título, Texto, Icono y Enlace
                    api.sendPush(
                        this.props.usuarios[this.state.usuario_uid].token,
                        "Ha recibido una pegatina de " + this.props.usuario.usuario + "!",
                        "'" + this.refComentario.current.value + "'",
                        "https://gamify.ctingenieros.es/img/twemoji/"+this.state.pegatina_codigo+".png",
                        "https://gamify.ctingenieros.es/"
                    );
                }

                // Ordenamos el cierre del formulario
				this.cerrar();
			}, error => {

                // Lanzamos una notificación de error y pasamos el nombre de usuario
                Notification_PegatinaError(this.props.usuarios[this.state.usuario_uid].usuario);
            });
		} else {
            // Lanzamos una notificación de aviso indicando que debe escribir un mensaje
            Notification_PegatinaAviso("Debe escribir un mensaje para la persona");
		}
		
        /*	Esta opción sirve para poder dar más de una pegatina a la vez, pero hay que resolver cómo construir la Promise para lanzar el cierre cuando esté todo.
		// Creamos una pegatina para cada destinatario
		for(let i=0,ii=this.refPara.current.selectedOptions.length;i<ii;i++){
			// Vemos quién es el destinatario
			let para = this.refPara.current.selectedOptions[i].value;
			// Guardamos la pegatina en la base de datos
			fb.newPegatina({
				de: this.refCampo1.current.value,
				para: this.refCampo2.current.value,
				tipo: this.refCampoN.current.value,
				comentario: this.refCampoN.current.value,
				fecha: this.refCampoN.current.value,
			}, function(usuarios){
				this.props.cerrar(true);
			}.bind(this));
		}
        */





	}

    onClickCancelar(evento){ evento.preventDefault();
        this.cerrar();
	}

	// ================================================================================================================================================================================================
	//	FUNCIONES
	// ================================================================================================================================================================================================

    cerrar(){
        // Ponemos en marcha la animación de salida
        window.document.getElementById("FormPegatinas").classList.add("fadeOut");
        window.document.getElementById("FormPegatinasContenido").classList.add("slideOutDown");
        // Las animaciones están en modo "faster", así que hay que esperar 500ms para eliminar el componente
        window.setTimeout(() => { this.props.cerrar(false); },500);
    }

	// ================================================================================================================================================================================================
	//	EFECTO EXPLOSIÓN DE ESTRELLAS
	// ================================================================================================================================================================================================

    iniciarEfecto(posX,posY){
        // Estilos que asignaremos aleatoriamente
        const estilos = ['txtRojo','txtAmarillo','txtVerde','txtAzul','txtGris'];
        // Creamos los objetos estrella
        for(let i=0, ii=20; i<ii; i++){
            // Creamos el elemento DIV de HTML y lo metemos en el DOM
            let objeto_Div = window.document.createElement("div");
            let estilo = estilos[Math.floor(Math.random() * estilos.length)];
            objeto_Div.innerHTML = "<i class='fas fa-star fa-2x "+estilo+"'>";
            objeto_Div.classList.add("efectoObjeto");
            window.document.body.appendChild(objeto_Div);
            // Construimos el objeto estrella
            let objeto = {};
            objeto.div = objeto_Div;
            objeto.r = 0;
            objeto.x = posX-15;
            objeto.y = posY-15;
            // Velocidad
            let v = Math.random()*100; let d = Math.random()*2*Math.PI;
            objeto.vr = Math.random()*this.efecto.velocidad_Max;
            objeto.vx = v*Math.cos(d);
            objeto.vy = v*Math.sin(d);
            // Lo metemos en el array de estrellas
            this.efecto.objetos.push(objeto);
        }

        // Iniciamos la animación
        this.efecto.handler = window.setInterval(this.loopEfecto.bind(this),50);
    }


    loopEfecto(){
        // Colocamos las estrellas y actualizamos sus posiciones
        for(let i=0, ii=this.efecto.objetos.length; i<ii; i++){
            this.efecto.objetos[i].div.style.left = this.efecto.objetos[i].x + "px";
            this.efecto.objetos[i].div.style.top = this.efecto.objetos[i].y + "px";
            this.efecto.objetos[i].div.style.transform = "rotate("+this.efecto.objetos[i].r+"deg)";
            this.efecto.objetos[i].div.style.opacity = 1-(this.efecto.tiempo/this.efecto.tiempo_Max);
            this.efecto.objetos[i].x += this.efecto.objetos[i].vx;
            this.efecto.objetos[i].y += this.efecto.objetos[i].vy;
            this.efecto.objetos[i].r += this.efecto.objetos[i].vr;
            this.efecto.objetos[i].vy += this.efecto.gravedad;
            this.efecto.objetos[i].vx *= this.efecto.rozamiento;
            this.efecto.objetos[i].vy *= this.efecto.rozamiento;
        }
        // Si ha pasado el tiempo máximo del efecto, eliminamos las estrellas
        if(this.efecto.tiempo++>this.efecto.tiempo_Max) {
            window.clearInterval(this.efecto.handler);
            Array.from(window.document.querySelectorAll(".efectoObjeto")).forEach(objeto => { objeto.parentNode.removeChild(objeto); });
        }
    }


}

export default FormPegatinas;
