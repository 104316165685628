// Librerías
import React from 'react';
import { NavLink } from 'react-router-dom';


class ListaNoticias extends React.Component {

	render(){
		
		const noticias = this.props.datos.lista;
		
		return(
			<div id="ListaNoticias" className="container">
				<div className="componente">
					<div className="row">
						<div className="col-12">
							<h3>Noticias</h3>
						</div>
					</div>
					{Object.keys(noticias).map(uid =>
						<NavLink key={uid} exact to={"/noticias/"+uid} className="noticia">
							<div className="row noticia">
							
								<div className="col-12 col-lg-4 p-3">
									<div className="imagen" style={{backgroundImage: "url(" + (noticias[uid].imagen || "/img/noticia.jpg") + ")"}}></div>
								</div>
								<div className="col-12 col-lg-8 p-3">
										<h4>{noticias[uid].titulo}</h4>
										<small>{this.parseFechaDMA(noticias[uid].fecha)}</small>
										<p>{noticias[uid].descripcion}</p>
								</div>
							</div>					
						</NavLink>
								
					)}
				</div>
			</div>
		);
		
	}
	// 
	// 
	parseFechaDMA(valor){
		if(valor===undefined) return "";
		const fecha = new Date(valor.seconds*1000);
		return ("00" + fecha.getDate()).slice(-2) + "/" + ("00" + (fecha.getMonth()+1)).slice(-2) + "/" + fecha.getFullYear();
	}
	
}

export default ListaNoticias;
