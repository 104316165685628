import React from 'react';

// Estilos
import '../../estilos/niveles.scss';


class Niveles extends React.Component {

    // ================================================================================================================================================================================================
    //  REACT
    // ================================================================================================================================================================================================

	render() {
		return(
			<div id="Niveles" className="container">
				<div className="row">
					
					<div className="col-12">
						<div className="componente">
							<h3>Nivel de participación</h3>
							<p>El nivel de participación sirve para medir cuánto usas la aplicación de gamificación. Cuanto más la uses mayor será tu nivel
							y te dará opción a más ventajas. El nivel sube cuando alcanzas ciertos puntos de participación, y puedes conseguir estos puntos
							sólo por entrar cada día en la aplicación, dándole pegatinas a tus compañeros, comentando las noticias...</p>
							<p>El numerito dentro de la estrella indica tu nivel y la barra de progreso muestra cuánto te falta para subir al siguiente nivel.</p>
							<div className="progress">
								<div className="progress-bar progress-bar-striped bg-danger progress-bar-animated" role="progressbar" style={{width: "63%"}} aria-valuenow="63" aria-valuemin="0" aria-valuemax="100"></div>
							</div>
						</div>
					</div> 

					<div className="col-12 col-lg-6">
						<div className="componente izquierda">
							<h3>Nivel actual</h3>
							<p>Tu nivel es: <span className="nivel">Nuevo</span></p>
						</div>
					</div> 

					<div className="col-12 col-lg-6">
						<div className="componente derecha">
							<h3>Puntos para siguiente nivel:</h3>
							<p>Te faltan 34 puntos para: <span className="nivel">Novato</span></p>
						</div>
					</div>
					
					<div className="col-12">
						<div className="componente">
							<h3>Descripción de los niveles</h3>
							<p>En esta tabla puedes consultar cuántos puntos de participación necesitas para alcanzar cada uno de los niveles. Cuanto más uses la aplicación, más puntos conseguirás y podrás convertirte en un experto.</p>

	<table class="table table-hover">
		<thead>
			<tr>
				<th scope="col">Nivel</th>
				<th scope="col" className="text-center">Puntos</th>
				<th scope="col">Descripción</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<th scope="row">1</th>
				<td className="text-center">0&nbsp;-&nbsp;199</td>
				<td><h4>Nuevo</h4>Los nuevos usuarios, son los que apenas ha empezado a utilizar la aplicación.</td>
			</tr>
			<tr>
				<th scope="row">2</th>
				<td className="text-center">200&nbsp;-&nbsp;999</td>
				<td><h4>Novato</h4>Los novatos, ya ha usado la aplicación y están poco a poco van descubriendo sus ventajas.</td>
			</tr>
			<tr>
				<th scope="row">3</th>
				<td className="text-center">1000&nbsp;-&nbsp;4999</td>
				<td><h4>Aprendiz</h4>Cuando llevas tiempo usando la aplicación y la usas regularmente, te conviertes en aprendiz.</td>
			</tr>
			<tr>
				<th scope="row">4</th>
				<td className="text-center">5000&nbsp;-&nbsp;9999</td>
				<td><h4>Miembro</h4>Ya conocen la aplicación perfectamente, la usan a menudo, y saben cómo sacarle todo el partido.</td>
			</tr>
		</tbody>
	</table>



						</div>
					</div> 
					
					
					
				</div>
			</div>
		);
	}
	
  
  
}

export default Niveles;