import React from 'react';
import {segundosXfecha} from './Funciones';

class Ultimas extends React.Component {

	// ================================================================================================================================================================================================
	//	REACT
	// ================================================================================================================================================================================================

	constructor(props) { super(props);

        // Inicializamos el state
        this.state = { ver: "global" };

        // Vinculamos los eventos con la instancia
        this.onClickVer = this.onClickVer.bind(this);

    }

    render() {

        // Cogemos el UID del usuario que está logueado
        let uidUsuarioLogueado = this.props.usuario.uid;

        // Lista de todas las pegatinas
        let pegatinas = this.props.pegatinas || {};

        // Lista de UIDs de todas las pegatinas del mes
        let uids = Object.keys(pegatinas);

        // Filtramos las pegatinas para quedarnos sólo las pegatinas dirigidas al usuario logueado
        if(this.state.ver==="recibidas") uids = uids.filter(uid => pegatinas[uid].para===uidUsuarioLogueado);

        // Filtramos las pegatinas para quedarnos sólo las pegatinas enviadas por el usuario logueado
        if(this.state.ver==="enviadas") uids = uids.filter(uid => pegatinas[uid].de===uidUsuarioLogueado);

        // Creamos un array nuevo recorriendo las pegatinas en orden inverso
        let ultimas = [];
        for(let i=uids.length-1, ii=0; i>=ii; i--) ultimas.push(pegatinas[uids[i]]);

		// Recortamos al número de pegatinas que queremos mostrar como mucho
		ultimas = ultimas.slice(0,7);


        return ( 
    
				<div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8"> 

					<div className="Ultimas componente">						
						<h3>
                            Últimas Pegatinas:
                            <button type="button" className={"btn btn-light btn-sm nofocus "+(this.state.ver==="global"?"active":"")} ver="global" onClick={this.onClickVer}>Global</button>
                            &nbsp;
                            <button type="button" className={"btn btn-light btn-sm nofocus "+(this.state.ver==="enviadas"?"active":"")} ver="enviadas" onClick={this.onClickVer}>Enviadas</button>
                            &nbsp;
                            <button type="button" className={"btn btn-light btn-sm nofocus "+(this.state.ver==="recibidas"?"active":"")} ver="recibidas" onClick={this.onClickVer}>Recibidas</button>
                        </h3>
						
                        {ultimas.length>0?
                            <ul className="lista">
                                {ultimas.map(function(pegatina){

                                    // Cogemos la imagen del usuario que envía la pegatina
                                    let imagenDe = "/img/usuario.jpg";
                                    if(pegatina.de_usuario && pegatina.de_usuario.imagen) imagenDe = pegatina.de_usuario.imagen;

                                    // Cogemos la imagen del usuario que recibe la pegatina
                                    let imagenPara = "/img/usuario.jpg";
                                    if(pegatina.para_usuario && pegatina.para_usuario.imagen) imagenPara = pegatina.para_usuario.imagen;

                                    return (
                                        <li key={pegatina.fecha.seconds}>
                                            <span className="pegatina">
                                                <img src={"./img/twemoji/"+pegatina.codigo+".png"} alt={"pegatina-"+pegatina.codigo} />
                                                <small className={pegatina.puntos<0?"negativo":"positivo"}>{pegatina.puntos}</small>
                                            </span>
                                            <div className="datos">											

                                                <span className="ct-usuario-h">
                                                    <div className="foto" style={{backgroundImage: "url("+imagenDe+")"}}></div>
                                                    <span className={pegatina.de_usuario.uid===uidUsuarioLogueado?"txtNegro":""}>{pegatina.de_usuario.usuario}</span>
                                                </span>
                                                <i className="fas fa-angle-right"></i>
                                                <span className="ct-usuario-h">
                                                    <div className="foto" style={{backgroundImage: "url("+imagenPara+")"}}></div>
                                                    <span className={pegatina.para_usuario.uid===uidUsuarioLogueado?"txtNegro":""}>{pegatina.para_usuario.usuario}</span>
                                                </span>

                                            </div>
                                            <div className="texto">
                                                <i>&laquo;{pegatina.comentario}&raquo;</i> <span className="fecha">({segundosXfecha(pegatina.fecha.seconds,true)})</span>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        :

                            <div className="text-center">No hay pegatinas todavía</div>
                        }
					</div>

				</div>


        );
	}
	

	// ================================================================================================================================================================================================
	//	EVENTOS
	// ================================================================================================================================================================================================

	onClickVer(evento){ evento.preventDefault();
		
        // Cambiamos a la vista que nos hayan dicho
        let ver = evento.currentTarget.getAttribute("ver");
        this.setState({ ver: ver });
		
    }

}

export default Ultimas;