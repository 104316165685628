import React from 'react';
import { FirebaseContext } from '../Firebase';
import { withRouter } from 'react-router-dom';

class Noticia extends React.Component {
		
	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;
	
	// ================================================================================================================================================================================================
	//	React
	// ================================================================================================================================================================================================


	constructor(props){ super(props);
		this.state = {
			uid: "",
			noticia: {}
		}
	}

	componentDidMount(){
		
		// Referencia a Firebase
		let api = this.context;
		
		// Cogemos el UID de la noticia que queremos editar ó la palabra "nueva" si es una noticia nueva
		const uid = this.props.location.pathname.replace("/noticias/","");
		
		// Guardamos el UID de la noticia que queremos cargar
		this.setState({ uid: uid });

		// Solicitamos la noticia a la base de datos
		api.getNoticia(uid,function(datos){
			let noticia = datos.data();
			this.setState({ noticia: noticia });
		}.bind(this));
		
	}
	
	render(){
		
		// Cogemos el UID de la noticia que queremos cargar
		//const uid = this.props.location.pathname.replace("/noticias/","");

		// Imagen que se mostrará, si no hay imagen se pondrá la imagen por defecto
		const imagen = this.state.noticia.imagen || "/img/noticia.jpg";

		return (
			<div id="Noticia" className="container">
				<div className="row">
					
						<div className="col-12 col-lg-6">
							<div className="componente izquierda">
								<div className="imagen" style={{backgroundImage: "url(" + imagen + ")"}}></div>
							</div>
						</div> 

						<div className="col-12 col-lg-6">
							<div className="componente derecha">
								<h3>{this.state.noticia.titulo}</h3>								
								<div className="descripcion" dangerouslySetInnerHTML={{ __html: this.state.noticia.descripcion }}></div>
								<small>Publicado el {this.parseFechaDMA(this.state.noticia.fecha)}</small>
							</div>
						</div>
				</div>
				<div className="row">

					<div className="col-12">
						<div className="componente contenido" dangerouslySetInnerHTML={{ __html: this.state.noticia.contenido }}></div>
					</div>
									
				</div>
				<div className="row">
					<div className="col-12">
						<div className="componente">
							Comentarios:
						</div>
					</div>
				</div>
				
			</div>
		);
		
	}

	
	parseFechaDMA(valor){
		if(valor===undefined) return "";
		const fecha = new Date(valor.seconds*1000);
		return ("00" + fecha.getDate()).slice(-2) + "/" + ("00" + (fecha.getMonth()+1)).slice(-2) + "/" + fecha.getFullYear();
	}
	
}

export default withRouter(Noticia);