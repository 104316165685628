// Librerías
import React from 'react';
import { NavLink } from 'react-router-dom';
import { FirebaseContext } from './Firebase';

// Estilos
import '../estilos/menulateral.scss';

class MenuLateral extends React.Component {

	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;

	getNivel(participacion){
		return 1;
	}

    render() {
		
		let imagen = this.props.usuario.imagen || "/img/usuario.jpg";
		
        return ( <React.Fragment>
				<nav id="MenuLateral">
					<h1>CT Gamify v0.02b</h1>
					<div className="perfil">
						
						<div className="ctCoins">
							<NavLink exact to="/premios" onClick={this.onClickOpcion.bind(this)}>
								<span className="numero">{this.props.usuario.ctcoins || 0}$</span>
								<i className="fas fa-circle fa-3x"></i>
							</NavLink>
						</div>
						
						<NavLink exact to="/configuracion" onClick={this.onClickOpcion.bind(this)}>
							<div className="foto" style={{backgroundImage: "url("+imagen+")"}}></div>
						</NavLink>

					</div>
					
					<div className="nivel">
						<NavLink exact to="/niveles" onClick={this.onClickOpcion.bind(this)}>
							<div className="izquierda">
								<span className="valor">{this.getNivel(this.props.usuario.participacion)}</span>
								<i className="fas fa-star fa-2x"></i>
							</div>
							<div className="derecha">
								<span className="nombre">{this.props.usuario.usuario}</span>
								<div className="progress">
									<div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{width: "63%"}} aria-valuenow="63" aria-valuemin="0" aria-valuemax="100"></div>
								</div>
							</div>
						</NavLink>
					</div>
					
					<div className="botones">
						<button className="btn btn-blanco nofocus" onClick={this.props.pegatina}><i className="fas fa-award fa-2x"></i><span> Dar Pegatina</span></button>
						{false && <a href="/pegatinas" data-toggle="modal" data-target="#mensajes-modal"><i className="fas fa-envelope fa-2x"></i></a>}
					</div>
					
					<ul className="opciones list-unstyled">
						<li>
							<NavLink exact to="/" activeClassName="active" onClick={this.onClickOpcion.bind(this)}>Inicio</NavLink>
						</li>
						<li>
							<NavLink exact to="/pegatinas" activeClassName="active" onClick={this.onClickOpcion.bind(this)}>Pegatinas</NavLink>
						</li>
						<li>
							<NavLink exact to="/noticias" activeClassName="active" onClick={this.onClickOpcion.bind(this)}>Noticias</NavLink>
						</li>
						<li>
						    <NavLink exact to="/chats" activeClassName="active" onClick={this.onClickOpcion.bind(this)}>Chats</NavLink>
						</li>
						<li>
						    <NavLink exact to="/perfiles" activeClassName="active" onClick={this.onClickOpcion.bind(this)}>Perfiles</NavLink>
						</li>
						<li>
							<NavLink exact to="/kanban" activeClassName="active" onClick={this.onClickOpcion.bind(this)}>Kanban (beta)</NavLink>
						</li>

					</ul>

					<div className="extras">
						<a type="button" className="btn nofocus" href="mailto:mmfernandez@ctingenieros.es"><i className="fas fa-envelope-open-text"></i></a>
						<button type="button" className="btn nofocus" onClick={this.onClickLogout.bind(this)}><i className="fas fa-power-off"></i></button>
                        <br />
                        
					</div>

				</nav>
                <div id="MenuCapa" onClick={this.onClickCapa.bind(this)}></div>
                </React.Fragment>
        );

    }
    
    onClickPrueba(){
        window.culo = this.props.usuario.saldoPegatinas.fecha;
        //<button onClick={this.onClickPrueba.bind(this)}>Prueba</button>
     }

	onClickCapa(){ document.getElementById("root").classList.remove("menu-abierto"); }

	// Elimina la clase "menu-abierto" para que se cierre el MenuLateral
	onClickOpcion(){ document.getElementById("root").classList.remove("menu-abierto"); }

	// Realiza el logout
	onClickLogout(){

		// Referencia a Firebase
		let api = this.context;

		// Nos desconectamos
		api.doLogout();
		
	}

}

export default MenuLateral;


/*


						<li>
							<NavLink exact to="/premios" activeClassName="active">Premios</NavLink>
						</li>


Ejemplo de sub-opciones:

                    <li className="active">
                        <a href="#menuPegatinas" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle">Pegatinas</a>
                        <ul className="collapse list-unstyled" id="menuPegatinas">
                            <li>
                                <a href="#">Historial</a>
                            </li>
                            <li>
                                <a href="#">Ranking</a>
                            </li>
                        </ul>
                    </li>
					
					
*/