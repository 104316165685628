import React from 'react';
import { FirebaseContext } from '../Firebase';

// Componentes
import Tablon from './Tablon';
import Evolucion from './Evolucion';
import Ultimas from './Ultimas';
import Ranking from './Ranking';

// Estilos
import '../../estilos/Pegatinas.scss'


class Pegatinas extends React.Component {
	
	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;

	// ================================================================================================================================================================================================
	//	React
	// ================================================================================================================================================================================================

    constructor(props){ super(props);
        
        // Inicializamos el State. Aquí sólo cargaremos todas las pegatinas del mes
        this.state = { cargado: false, pegatinas: [] };
        
	}

	componentDidMount(){

		// Si se carga directamente la página de pegatinas, es posible que no tengamos datos de props.usuarios, así que no haremos nada
		if(!this.props.usuarios) alert("Error");

		// Referencia a Firebase
		let api = this.context;

		// Mes y Año actual
		let mes = new Date().getMonth();
		let ano = new Date().getFullYear();

		// Cargamos las pegatinas
		api.getPegatinas(mes, ano, pegatinas => {
			
			// Lista de UIDs de todas las pegatinas del mes
			let uids = Object.keys(pegatinas);
			
			// Añadimos a cada pegatina los datos del usuario "de" y el usuario "para"
			uids.forEach(uid => {
				pegatinas[uid].de_usuario = this.props.usuarios[pegatinas[uid].de];
				pegatinas[uid].para_usuario = this.props.usuarios[pegatinas[uid].para];
			});

            // Ya podemos indicar que se han cargado las pegatinas
            this.setState({ cargado: true, pegatinas: pegatinas });

		});
		
	}
	
	render(){
		if(!this.state.cargado) return null;
		return(
			<div id="Pegatinas" className="container">
				<div className="row">
                    
					<Tablon usuario={this.props.usuario} pegatinas={this.state.pegatinas} />
                    <Evolucion usuario={this.props.usuario} pegatinas={this.state.pegatinas} />
                    <Ultimas usuario={this.props.usuario} pegatinas={this.state.pegatinas} />
                    <Ranking usuario={this.props.usuario} usuarios={this.props.usuarios} pegatinas={this.state.pegatinas} />

				</div>
			</div>
		);
		
	}

}

export default Pegatinas;