import React from 'react';

class Informacion extends React.Component {

	constructor(props){ super(props);

        // Vinculamos los eventos con la instancia
		this.onClickEliminar = this.onClickEliminar.bind(this);
		
	}

    render() {

		let informacion = this.props.informacion;

        return (
    
			<React.Fragment>
				<div key={informacion.uid} id={"info"+informacion.uid} className="col-12 col-xs-12 col-sm-12 col-md-12 col-xl-12 animated fastest">
					<div className="Informacion componente">
						<button className="btn btn-blanco btn-limpio cerrar" uid={informacion.uid} onClick={this.onClickEliminar}><i className="fas fa-times-circle fa-2x txtRojo"></i></button>

						<h3>{informacion.titulo}</h3>
						<div dangerouslySetInnerHTML={{ __html: informacion.contenido }}></div>

					</div>
				</div>
			</React.Fragment>
      
        );
    }

	onClickEliminar(evento){ evento.preventDefault();
	
		let uid = evento.currentTarget.getAttribute("uid");
		this.props.eliminar(uid);
		
	}
	
}

export default Informacion;
