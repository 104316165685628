import React from 'react';
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/messaging';

const configuracion = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID
};

const FirebaseContext = React.createContext(null);

class Firebase {
	
	constructor() {
		app.initializeApp(configuracion);
		this.auth = app.auth();
		this.firestore = app.firestore();
        this.storage = app.storage();
        this.messaging = app.messaging();

        // Activamos los mensajes PUSH
        this.messaging.usePublicVapidKey(process.env.REACT_APP_MESSAGING_VAPID);

	}



    allowMessaging(callback, fallback){

        // Solicitamos permiso para mostrar notificaciones
        Notification.requestPermission().then(permiso => {
            if(permiso === 'denied') { fallback({ code: "message/denied", message: "Se ha denegado el permiso a mostrar notificaciones"}); }
            if(permiso === 'default') { fallback({ code: "message/cancel", message: "Se ha cancelado la petición de permiso a mostrar notificaciones"}); }
            if(permiso === 'granted') {
                // Si tenemos permiso del usuario, solicitamos un token a Firebase
                this.messaging.getToken().then(token => {
                    if(token) {
                        // Si recibimos un token válido llamamos a la función callback
                        callback(token);
                    } else {
                      // No hemos obtenido ningún token por algún motivo
                      fallback({ code: "message/voidtoken", message: "No se ha recibido un token válido de Firebase"});
                    }
                }).catch(error => { fallback(error); });
            }
        });
    }

    sendPush(destinatario, titulo, texto, icono, enlace, tag){
        if(tag===undefined) tag = Math.random().toString(36).substring(2);
        fetch('https://fcm.googleapis.com/fcm/send', {
            'method': 'POST',
            'headers': {
                'Authorization': 'key=' + process.env.REACT_APP_MESSAGING_SERVER,
                'Content-Type': 'application/json'
            },
            'body': JSON.stringify({
                'to': destinatario,
                "data":{
                    "title": titulo,
                    "body": texto,
                    "icon": icono,  
                    "tag": tag,
                    "link": enlace,
                    "uno" : "1",
                    "dos" : "2",
                    "tres" : "3"
                }
            })
        })
        .then(response => { console.log(response); })
        .catch(error => { console.error(error); });
        
    }

	// ================================================================================================================================================================================================
	//  LOGIN: Funciones de login/logout/etc...
	// ================================================================================================================================================================================================
    
    /**
    * Comprueba en Firebase Auth que el email y password introducidos son correctos, y si lo son, pone al usuario como usuario logueado, por lo que también se disparará el evento onChangeUser.
    * @param {String} email El email del usuario que solicita el login
    * @param {String} password El password del usuario que solicita el login
    * @param {Function} callback Función que se ejecutará en caso de que todo vaya bien. Será llamada con el objeto User (User de Auth) como argumento
    * @param {Function} fallback Función que se ejecutará en caso de que algo falle. Será llamada con un String como argumento que indicará el motivo del fallo
    * @see auth.Auth.signInWithEmailAndPassord() [Method]: https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signinwithemailandpassword
    * @see auth.UserCredential [Type]: https://firebase.google.com/docs/reference/js/firebase.auth#usercredential
    * @see User [Type]: https://firebase.google.com/docs/reference/js/firebase.User
    */
    doLogin(email, password, callback, fallback){

        // Solicitamos hacer login con email y password
        this.auth.signInWithEmailAndPassword(email,password)
        .then(userCredential => {
            // Si todo va bien, recibiremos un objeto UserCredential, llamamos al callback pasándole el objeto User (userCredential.user.uid es el UID del usuario)
            callback && callback(userCredential.user);
        })
        .catch(error => {
            // En caso de error, recibiremos un objeto Error { code: "", message: ""}, llamamos al fallback pasándole el motivo traducido a un String
            let motivo = {
                "auth/invalid-email": "El Email no es válido",
                "auth/user-disabled": "El usuario ha sido desactivado",
                "auth/user-not-found": "No se ha encontrado al usuario",
                "auth/wrong-password": "La contraseña no es correcta",
                "auth/too-many-requests": "Demasiados intentos"
            }[error.code] || "["+error.code+"]: " + error.message;
            fallback && fallback(motivo);            
        });
    }

    /**
    * Hace el logout del usuario en Firebase Auth. Al eliminar al usuario como usuario logueado, se disparará el evento onUserChange
    * @see aut.Auth.signOut() [Method]: https://firebase.google.com/docs/reference/js/firebase.auth.Auth#signout
    */
    doLogout(){

        // Eliminamos al usuario de la sesión
        this.auth.signOut();

    }

    /**
    * Solicita a Firebase Auth que envíe un mail de reseteo de password a un usuario
    * @param {String} email El email del usuario al que se enviará el email
    * @param {Function} callback Función que se ejecutará en caso de que todo vaya bien. No recibirá ningún argumento
    * @param {Function} fallback Función que se ejecutará en caso de que algo falle. Será llamada con un String como argumento que indicará el motivo del fallo
    * @see auth.Auth.sendPasswordResetEmail() [Method]: https://firebase.google.com/docs/reference/js/firebase.auth.Auth#send-password-reset-email
    */
    doReset(email, callback, fallback){

        // Solicitamos que se envíe un email de reseteo de contraseña
        this.auth.sendPasswordResetEmail(email)
        .then(() => {
            // Si el envío del mail para resetear el password se hace correctamente, llamamos al callback (sin pasarle nada)
            callback && callback();
        })
        .catch(error => {
            // En caso de error, recibiremos un objeto Error { code: "", message: ""}, llamamos al fallback pasándole el motivo traducido a un String
            let motivo = {
                "auth/invalid-email": "El Email no es válido",
                "auth/missing-android-pkg-name": "Error al enviar email",
                "auth/missing-continue-uri": "Error al enviar email",
                "auth/missing-ios-bundle-id": "Error al enviar email",
                "auth/invalid-continue-uri": "Error al enviar email",
                "auth/unauthorized-continue-uri": "Error al enviar email",
                "auth/user-not-found": "El usuario no existe"
            }[error.code] || "["+error.code+"]: " + error.message;
            fallback && fallback(motivo);            
        });

    }

    /**
    * Establece una función para responder al evento onAuthStateChanged, que ocurre cuando cambia el usuario logueado
    * @param {Function} callback Función que se ejecutará en caso de cambie el usuario logueado. Será llamada con el nuevo objeto User como argumento
    * @see auth.Auth.onAuthStateChanged() [Event]: https://firebase.google.com/docs/reference/js/firebase.auth.Auth#on-auth-state-changed
    */
	onChangeUser(callback){

        // Establecemos una función que responda al evento onAuthStateChanged
        this.auth.onAuthStateChanged(callback);
        
	}

    /**
    * Permite a un usuario crear una cuenta nueva de usuario nueva con su email y un password. Si la creación tiene éxito, el usuario quedará logueado en la aplicación
    * @param {String} email El email del usuario que solicita el alta
    * @param {String} password El password elegido por el usuario
    * @param {Function} callback Función que se ejecutará en caso de que todo vaya bien. Será llamada con el objeto User como argumento
    * @param {Function} fallback Función que se ejecutará en caso de que algo falle. Será llamada con un String como argumento que indicará el motivo del fallo
    * @see auth.Auth.createUserWithEmailAndPassword() [Method]: https://firebase.google.com/docs/reference/js/firebase.auth.Auth#create-user-with-email-and-password
    * @see auth.UserCredential [Type]: https://firebase.google.com/docs/reference/js/firebase.auth#usercredential
    */
    doSignup(email, password, callback, fallback){

        // Solicitamos la creación del usuario a Firebase Auth
        this.auth.createUserWithEmailAndPassword(email, password)
        .then(userCredential => {
            // Si todo va bien, recibiremos un objeto UserCredential, llamamos al callback pasándole el usuario (userCredential.user.uid es el UID del usuario)
            callback && callback(userCredential.user);
        })
        .catch(error => {
            // En caso de error, recibiremos un objeto Error { code: "", message: ""}, llamamos al fallback pasándole el motivo traducido a un String
            let motivo = {
                "auth/invalid-email": "El Email no es válido",
                "auth/email-already-in-use": "Ese Email ya está registrado",
                "auth/operation-not-allowed": "No se permiten nuevos registros",
                "auth/weak-password": "El password es demasiado sencillo"
            }[error.code] || "["+error.code+"]: " + error.message;
            fallback && fallback(motivo);            
        });

    }


    /**
    * Cambia el password del usuario logueado
    * @param {String} password Nuevo password elegido por el usuario
    * @param {Function} callback Función que se ejecutará en caso de que todo vaya bien. No recibirá ningún argumento
    * @param {Function} fallback Función que se ejecutará en caso de que algo falle. Será llamada con un String como argumento que indicará el motivo del fallo
    * @see auth.Auth.currentUser [Property]: https://firebase.google.com/docs/reference/js/firebase.auth.Auth#currentuser
    * @see User.updatePassword() [Method]: https://firebase.google.com/docs/reference/js/firebase.User#updatepassword
    */
    doChangePassword(password, callback, fallback){

        // Solicitamos el cambio de password del usuario logueado a Firebase Auth
        this.auth.currentUser.updatePassword(password)
        .then(() => {
            // Si el envío del mail para resetear el password se hace correctamente, llamamos al callback (sin pasarle nada)
            callback && callback();
        })
        .catch(error => {
            // En caso de error, recibiremos un objeto Error { code: "", message: ""}, llamamos al fallback pasándole el motivo traducido a un String
            let motivo = {
                "auth/invalid-email": "El Email no es válido",
                "auth/requires-recent-login": "Debe volver a loguearse"
            }[error.code] || "["+error.code+"]: " + error.message;
            fallback && fallback(motivo);            
        });

    }


	// ================================================================================================================================================================================================
	//  USERS: Funciones relacionadas con Usuarios
	// ================================================================================================================================================================================================

    /**
    * Devuelve la lista completa de usuarios en forma de objeto clave/valor, donde la clave es el UID del usuario y el valor es el objeto Usuario
    * @param {Function} callback Función que se ejecutará en caso de que todo vaya bien. Recibe como argumento un objeto con la lista de usuarios
    * @param {Function} fallback Función que se ejecutará en caso de que algo falle. Será llamada con un String como argumento que indicará el motivo del fallo
    * @see firestore.CollectionReference.get() [Method]: https://firebase.google.com/docs/reference/js/firebase.firestore.CollectionReference#get
    * @see firestore.QuerySnapshot [Type]: https://firebase.google.com/docs/reference/js/firebase.firestore.QuerySnapshot
    * @see firestore.QueryDocumentSnapshot [Type]: https://firebase.google.com/docs/reference/js/firebase.firestore.QueryDocumentSnapshot
    */
    getUsers(callback, fallback){

        this.firestore.collection("usuarios").get()
        .then(querySnapShot => {
            let usuarios = {};
            // Cogemos los registros uno por uno y añadimos el UID a cada usuario
			querySnapShot.forEach(queryDocumentSnapshot => {
                usuarios[queryDocumentSnapshot.id] = queryDocumentSnapshot.data();
                usuarios[queryDocumentSnapshot.id].uid = queryDocumentSnapshot.id;
            });
			callback && callback(usuarios);
        })
        .catch(error => {
            // En caso de error, recibiremos un objeto Error { code: "", message: ""}, llamamos al fallback pasándole el motivo traducido a un String
            let motivo = {
                "invalid-argument": "No se pudo cargar el listado"
            }[error.code] || "["+error.code+"]: " + error.message;
            fallback && fallback(motivo);
        });
	}

    /**
    * Crea un nuevo usuario a partir del email y el password, igual que doSignup pero sin ponerlo como usuario logueado. También le da de alta en la tabla de usuarios
    * @param {String} email El email del usuario que queremos dar de alta
    * @param {String} password El password elegido para ese usuario
    * @param {Function} callback Función que se ejecutará en caso de que todo vaya bien. Será llamada con el objeto Usuario como argumento, aunque sólo tendrá email y UID
    * @param {Function} fallback Función que se ejecutará en caso de que algo falle. Será llamada con un String como argumento que indicará el motivo del fallo
    * @see auth.Auth.currentUser [Property]: https://firebase.google.com/docs/reference/js/firebase.auth.Auth#currentuser
    * @see auth.Auth.updateCurrentUser() [Method]: https://firebase.google.com/docs/reference/js/firebase.auth.Auth#updatecurrentuser
    */
    newUser(email, password, callback, fallback){

        // Debido a que el método doSignup cambia el usuario logueado al nuevo usuario, tenemos que guardar al usuario actual para poder devolverlo como usuario logueado
        let usuarioActual = this.auth.currentUser;

        // Creamos al usuario usando el método doSignup
        this.doSignup(email, password, usuarioNuevo => {

            // Si todo ha ido bien, el nuevo usuario se queda como usuario logueado, así que cambiamos al usuario anterior
            this.auth.updateCurrentUser(usuarioActual).then(()=>{

                // Guardamos al nuevo usuario en la tabla Usuarios de la base de datos con su UID y su email
                let usuario = { uid: usuarioNuevo.uid, email: email };
                this.setUser(usuario.uid, usuario,() => {
                    //  Y cuando esto acabe, devolvemos el usuario mediante una función de callback
                    callback(usuario);
                }, fallback );

            }, fallback )

        }, fallback );
    }

    /**
    * Obtiene los datos de un usuario concreto a partir de su UID
    * @param {String} uid Es el UID del usuario que queremos
    * @param {Function} callback Función que se ejecutará en caso de que todo vaya bien. Recibe como argumento el objeto usuario
    * @param {Function} fallback Función que se ejecutará en caso de que algo falle. Será llamada con un String como argumento que indicará el motivo del fallo
    * @see firestore.DocumentReference.get() [Method]: https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentReference#get
    * @see firestore.DocumentSnapShot [Type]: https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentSnapshot
    */
	getUser(uid, callback, fallback){
        this.firestore.collection("usuarios").doc(uid).get()
        .then(documentSnapShot => {
            // Llamamos a la función callback pasándole los datos del usuario, y le añadimos también el UID
            callback({ ...documentSnapShot.data(), uid: uid });
        })
        .catch(error => {
            // En caso de error, recibiremos un objeto Error { code: "", message: ""}, llamamos al fallback pasándole el motivo traducido a un String
            let motivo = {
                "invalid-argument": "No se pudo cargar el usuario"
            }[error.code] || "["+error.code+"]: " + error.message;
            fallback && fallback(motivo);
        });
	}

    /**
    * Guarda los datos de un usuario concreto
    * @param {String} uid Es el UID con el que queremos guardar al usuario
    * @param {Object} usuario El objeto usuario que queremos guardar
    * @param {Function} callback Función que se ejecutará en caso de que todo vaya bien. Recibirá como argumento el objeto Usuario que acabamos de guardar
    * @param {Function} fallback Función que se ejecutará en caso de que algo falle. Será llamada con un String como argumento que indicará el motivo del fallo
    * @see firestore.DocumentReference.set() [Method]: https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentReference#set
    */
    setUser(uid, usuario, callback, fallback){

        this.firestore.collection("usuarios").doc(uid).set(usuario, {merge: true})
        .then(() => {
            // Llamamos a la función callback pasándole los datos del usuario, y le añadimos también el UID
            callback && callback({ ...usuario, uid: uid });
        })
        .catch(error => {
            // En caso de error, recibiremos un objeto Error { code: "", message: ""}, llamamos al fallback pasándole el motivo traducido a un String
            let motivo = {
                "invalid-argument": "No se pudo guardar el usuario"
            }[error.code] || "["+error.code+"]: " + error.message;
            fallback && fallback(motivo);
        });
    }


	// ================================================================================================================================================================================================
	//  NOTICIAS: Funciones relacionadas con Noticias
	// ================================================================================================================================================================================================
	
	getNoticia(uid,funcion){
		this.firestore.collection("noticias").doc(uid).get().then(funcion);
	}

    /**
    * Obtiene la noticia publicada, con fecha de publicación más cercana a la fecha actual, pero menor a la fecha actual.
    * Esto último es importante porque se pueden programar noticias indicando fechas futuras, pero no deben aparecer hasta esa fecha.
    * @param {Function} callback Función que se ejecutará en caso de que todo vaya bien. Esta función se ejecutará recibiendo como argumento el objeto solicitado por su UID.
    * @param {Function} fallback Función que se ejecutará en caso de que algo falle. Esta función se ejecutará recibiendo un String como argumento que indicará el motivo del fallo.
    * @see firestore.DocumentReference.get() [Method]: https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentReference#get
    * @see firestore.DocumentSnapShot [Type]: https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentSnapshot
    */
    getUltimaNoticia(callback, fallback){
        this.firestore.collection("noticias").where("publicada","==",true).orderBy("fecha","desc").limit(1).get()
        .then(querySnapShot => {
            let registro = {};
            // Cogemos los registros uno por uno (aunque sólo hemos solicitado uno) y añadimos el UID a cada registro
            querySnapShot.forEach(queryDocumentSnapshot => {
                registro = queryDocumentSnapshot.data();
                registro.uid = queryDocumentSnapshot.id;
            });
            callback && callback(registro);
        })
        .catch(error => {
            // En caso de error, recibiremos un objeto Error { code: "", message: ""}, llamamos al fallback pasándole el motivo traducido a un String
            let motivo = {
                "invalid-argument": "No se pudo cargar el registro"
            }[error.code] || "["+error.code+"]: " + error.message;
            fallback && fallback(motivo);
        });
    }

    /**
    * Obtiene la noticia publicada, con fecha de publicación más cercana a la fecha actual, pero menor a la fecha actual.
    * Esto último es importante porque se pueden programar noticias indicando fechas futuras, pero no deben aparecer hasta esa fecha.
    * @param {Function} callback Función que se ejecutará en caso de que todo vaya bien. Esta función se ejecutará recibiendo como argumento el objeto solicitado por su UID.
    * @param {Function} fallback Función que se ejecutará en caso de que algo falle. Esta función se ejecutará recibiendo un String como argumento que indicará el motivo del fallo.
    * @see firestore.DocumentReference.get() [Method]: https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentReference#get
    * @see firestore.DocumentSnapShot [Type]: https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentSnapshot
    */
   getUltimasNoticias(numero, callback, fallback){
    this.firestore.collection("noticias").where("publicada","==",true).where("fecha","<",new Date()).orderBy("fecha","desc").limit(numero).get()
    .then(querySnapShot => {
        let registros = {};
        // Cogemos los registros uno por uno y añadimos el UID a cada registro
        querySnapShot.forEach(queryDocumentSnapshot => {
            registros[queryDocumentSnapshot.id] = queryDocumentSnapshot.data();
            registros[queryDocumentSnapshot.id].uid = queryDocumentSnapshot.id;
        });
        callback && callback(registros);
    })
    .catch(error => {
        // En caso de error, recibiremos un objeto Error { code: "", message: ""}, llamamos al fallback pasándole el motivo traducido a un String
        let motivo = {
            "invalid-argument": "No se pudo cargar el registro"
        }[error.code] || "["+error.code+"]: " + error.message;
        fallback && fallback(motivo);
    });
}


	getNoticias(funcion,orden,limite){
		let coleccion = this.firestore.collection("noticias");
		coleccion = coleccion.where("publicada","==",true);
		// Vemos si nos han dicho un orden específico
		if(orden){
			// Cogemos el campo por el que queremos ordenar
			let campo = orden.split(";")[0];
			// Cogemos la dirección, que si no está será ascendente por defecto
			let direccion = orden.split(";")[1] || "asc";
			coleccion = coleccion.orderBy(campo,direccion);
		}
		// Vemos si nos han dado un límite de resultados
		if(limite) coleccion = coleccion.limit(limite);
		// Metemos los Where que hagan falta
		// Ejecutamos la consulta
		coleccion.get().then(function(lista){
			let noticias = {};
			lista.forEach((documento) => { noticias[documento.id] = documento.data(); });
			funcion(noticias);
		});
	}

	setNoticia(uid,noticia,funcion){
		if(uid===""){
			this.firestore.collection("noticias").add(noticia).then(funcion);
		}else{
			this.firestore.collection("noticias").doc(uid).set(noticia, {merge: true}).then(funcion);
		}
	}
	
	delNoticia(uid,funcion){
		this.firestore.collection("noticias").doc(uid).delete().then(funcion);
	}

	// ================================================================================================================================================================================================
	//  PEGATINAS: Funciones relacionadas con Pegatinas
	// ================================================================================================================================================================================================
	
	newPegatina(pegatina,funcionOk,funcionError){
		this.firestore.collection("pegatinas").add(pegatina).then(funcionOk).catch(funcionError);
	}
	
	getPegatinas(mes, ano, funcion){
		this.firestore.collection("pegatinas").where('fecha','>=',new Date(ano,mes,1)).where('fecha','<',new Date(ano,mes+1,1)).orderBy('fecha','asc').onSnapshot(lista => {
			let pegatinas = {};
			lista.forEach((documento) => { pegatinas[documento.id] = documento.data(); });
			funcion(pegatinas);
        });
	}

	// ================================================================================================================================================================================================
	//  INFORMACIONES: Funciones relacionadas con los registros de Información que aparecen en la pantalla de inicio
	// ================================================================================================================================================================================================
    
    
	getInformaciones(uidUsuario, callback, fallback){
        this.firestore.collection("informaciones").where("usuario","==",uidUsuario).get()
        .then(querySnapShot => {
            let registros = {};
            // Cogemos los registros uno por uno y añadimos el UID a cada registro
            querySnapShot.forEach(queryDocumentSnapshot => {
                registros[queryDocumentSnapshot.id] = queryDocumentSnapshot.data();
                registros[queryDocumentSnapshot.id].uid = queryDocumentSnapshot.id;
            });
            callback && callback(registros);
        })
        .catch(error => {
            // En caso de error, recibiremos un objeto Error { code: "", message: ""}, llamamos al fallback pasándole el motivo traducido a un String
            let motivo = {
                "invalid-argument": "No se pudo cargar el listado"
            }[error.code] || "["+error.code+"]: " + error.message;
            fallback && fallback(motivo);
        });
    }
    
	getInformacion(uid,funcion){
		this.firestore.collection("informaciones").where("usuario","==",uid).get().then(function(lista){
			let informacion = {};
			lista.forEach((documento) => { informacion[documento.id] = documento.data(); });
			funcion(informacion);
		});
	}
	
	delInformaciones(uid,funcion){
		this.firestore.collection("informaciones").doc(uid).delete().then(funcion);
	}
	
	// ================================================================================================================================================================================================
	//  CHAT: Funciones relacionadas con el chat de la aplicación
	// ================================================================================================================================================================================================
    
    /**
    * Obtiene los datos de un Chat a partir de su UID
    * @param {String} uid Es el UID del registro que queremos
    * @param {Function} callback Función que se ejecutará en caso de que todo vaya bien. Esta función se ejecutará recibiendo como argumento el objeto solicitado por su UID.
    * @param {Function} fallback Función que se ejecutará en caso de que algo falle. Esta función se ejecutará recibiendo un String como argumento que indicará el motivo del fallo.
    * @see firestore.DocumentReference.get() [Method]: https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentReference#get
    * @see firestore.DocumentSnapShot [Type]: https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentSnapshot
    */
    getChat(uid, callback, fallback){
        this.firestore.collection("chats").doc(uid).get()
        .then(documentSnapShot => {
            // Llamamos a la función callback pasándole los datos del registro, y le añadimos también el UID
            callback({ ...documentSnapShot.data(), uid: uid });
        })
        .catch(error => {
            // En caso de error, recibiremos un objeto Error { code: "", message: ""}, llamamos al fallback pasándole el motivo traducido a un String
            let motivo = {
                "invalid-argument": "No se pudo cargar el registro"
            }[error.code] || "["+error.code+"]: " + error.message;
            fallback && fallback(motivo);
        });
    } 

    newChatIndividual(uid1, uid2, callback, fallback){

        // Nos piden crear un nuevo chat individual con los usuarios del array uids
        let chat = {
            nombre: "",
            tipo: "individual",
            participantes: [uid1, uid2],
            fechaCreacion: new Date()
        };

        this.firestore.collection("chats").add(chat)
        .then(documentReference => {
            // Devolvemos el chat que hemos añadido, y le añadimos también el UID ahora que lo sabemos
            callback({ ...chat, uid: documentReference.id });
        })
        .catch(error => {
            // En caso de error, recibiremos un objeto Error { code: "", message: ""}, llamamos al fallback pasándole el motivo traducido a un String
            let motivo = {
                "invalid-argument": "No se pudo cargar el registro"
            }[error.code] || "["+error.code+"]: " + error.message;
            fallback && fallback(motivo);
        });
    }

    /**
    * Devuelve la lista de Chats en los que participa un cierto usuario indicado por su UID.
    * @param {Function} callback Función que se ejecutará en caso de que todo vaya bien. Esta función se ejecutará recibiendo como argumento un objeto con la lista de registros.
    * @param {Function} fallback Función que se ejecutará en caso de que algo falle. Esta función se ejecutará recibiendo un String como argumento que indicará el motivo del fallo.
    * @see firestore.CollectionReference.get() [Method]: https://firebase.google.com/docs/reference/js/firebase.firestore.CollectionReference#get
    * @see firestore.QuerySnapshot [Type]: https://firebase.google.com/docs/reference/js/firebase.firestore.QuerySnapshot
    * @see firestore.QueryDocumentSnapshot [Type]: https://firebase.google.com/docs/reference/js/firebase.firestore.QueryDocumentSnapshot
    */
    getChats(uidUsuario, callback, fallback){

        this.firestore.collection("chats").where("participantes","array-contains",uidUsuario).get()
        .then(querySnapShot => {
            let registros = {};
            // Cogemos los registros uno por uno y añadimos el UID a cada registro
            querySnapShot.forEach(queryDocumentSnapshot => {
                registros[queryDocumentSnapshot.id] = queryDocumentSnapshot.data();
                registros[queryDocumentSnapshot.id].uid = queryDocumentSnapshot.id;
            });
            callback && callback(registros);
        })
        .catch(error => {
            // En caso de error, recibiremos un objeto Error { code: "", message: ""}, llamamos al fallback pasándole el motivo traducido a un String
            let motivo = {
                "invalid-argument": "No se pudo cargar el listado"
            }[error.code] || "["+error.code+"]: " + error.message;
            fallback && fallback(motivo);
        });
    }

    getChatMensajes(uidChat, timestamp, callback){
        this.firestore.collection("chats/"+uidChat+"/mensajes").where('fecha','<=',timestamp).orderBy('fecha','desc').limit(20).get()
        .then(lista => {
            let mensajes = [];
            lista.forEach((documento) => { mensajes.push({ ...documento.data(), uid: documento.id }); });
            callback(mensajes.reverse());
        });
    }
    
    startChatMensajes(uidChat, timestamp, callback){
        return this.firestore.collection("chats/"+uidChat+"/mensajes").where('fecha','>',timestamp).orderBy('fecha','desc')
        .onSnapshot(lista => {
            let mensajes = [];
            lista.docChanges().forEach(cambio => {
                if(cambio.type === 'added') {
                    mensajes.push({ ...cambio.doc.data(), uid: cambio.doc.id });
                }
            })
            callback(mensajes.reverse());
        });
    }

	newChatMensajes(uidChat, mensaje, callback){
        // Actualizamos la fecha de último mensaje en el Chat y el texto del último mensaje
        this.firestore.collection("chats").doc(uidChat).set({ fechaUltimo: new Date(), textoUltimo: mensaje.texto }, {merge: true});
        // Guardamos el nuevo mensaje
        this.firestore.collection("chats/"+uidChat+"/mensajes").add(mensaje).then(callback);
	}

	// ================================================================================================================================================================================================
	//  PERFILES: Funciones relacionadas con los perfiles de equipo y usuario de la aplicación
	// ================================================================================================================================================================================================
    
    getPerfiles(callback, fallback){

        this.firestore.collection("perfiles").get()
        .then(querySnapShot => {
            let registros = {};
            // Cogemos los registros uno por uno y añadimos el UID a cada registro
            querySnapShot.forEach(queryDocumentSnapshot => {
                registros[queryDocumentSnapshot.id] = queryDocumentSnapshot.data();
                registros[queryDocumentSnapshot.id].uid = queryDocumentSnapshot.id;
            });
            callback && callback(registros);
        })
        .catch(error => {
            // En caso de error, recibiremos un objeto Error { code: "", message: ""}, llamamos al fallback pasándole el motivo traducido a un String
            let motivo = {
                "invalid-argument": "No se pudo cargar el listado"
            }[error.code] || "["+error.code+"]: " + error.message;
            fallback && fallback(motivo);
        });

    }

    getPerfil(uid, callback, fallback){
        this.firestore.collection("perfiles").doc(uid).get()
        .then(documentSnapShot => {
            // Llamamos a la función callback pasándole los datos del registro, y le añadimos también el UID
            callback({ ...documentSnapShot.data(), uid: uid });
        })
        .catch(error => {
            // En caso de error, recibiremos un objeto Error { code: "", message: ""}, llamamos al fallback pasándole el motivo traducido a un String
            let motivo = {
                "invalid-argument": "No se pudo cargar el registro"
            }[error.code] || "["+error.code+"]: " + error.message;
            fallback && fallback(motivo);
        });
    }

    
}

export default Firebase;
export { FirebaseContext };





    /**
    * Obtiene los datos de un registro único concreto a partir de su UID dentro de una colección
    * @param {String} uid Es el UID del registro que queremos
    * @param {Function} callback Función que se ejecutará en caso de que todo vaya bien. Esta función se ejecutará recibiendo como argumento el objeto solicitado por su UID.
    * @param {Function} fallback Función que se ejecutará en caso de que algo falle. Esta función se ejecutará recibiendo un String como argumento que indicará el motivo del fallo.
    * @see firestore.DocumentReference.get() [Method]: https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentReference#get
    * @see firestore.DocumentSnapShot [Type]: https://firebase.google.com/docs/reference/js/firebase.firestore.DocumentSnapshot
    */
/*  
    getRegistro(coleccion, uid, callback, fallback){
        this.firestore.collection(coleccion).doc(uid).get()
        .then(documentSnapShot => {
            // Llamamos a la función callback pasándole los datos del registro, y le añadimos también el UID
            callback({ ...documentSnapShot.data(), uid: uid });
        })
        .catch(error => {
            // En caso de error, recibiremos un objeto Error { code: "", message: ""}, llamamos al fallback pasándole el motivo traducido a un String
            let motivo = {
                "invalid-argument": "No se pudo cargar el registro"
            }[error.code] || "["+error.code+"]: " + error.message;
            fallback && fallback(motivo);
        });
    }
*/

    /**
    * Devuelve la lista completa de registros de una colección en forma de objeto clave/valor, donde la clave es el UID del registro y el valor es el objeto.
    * @param {Function} callback Función que se ejecutará en caso de que todo vaya bien. Esta función se ejecutará recibiendo como argumento un objeto con la lista de registros.
    * @param {Function} fallback Función que se ejecutará en caso de que algo falle. Esta función se ejecutará recibiendo un String como argumento que indicará el motivo del fallo.
    * @see firestore.CollectionReference.get() [Method]: https://firebase.google.com/docs/reference/js/firebase.firestore.CollectionReference#get
    * @see firestore.QuerySnapshot [Type]: https://firebase.google.com/docs/reference/js/firebase.firestore.QuerySnapshot
    * @see firestore.QueryDocumentSnapshot [Type]: https://firebase.google.com/docs/reference/js/firebase.firestore.QueryDocumentSnapshot
    */
/*
    getRegistros(coleccion, callback, fallback){

        this.firestore.collection(coleccion).get()
        .then(querySnapShot => {
            let registros = {};
            // Cogemos los registros uno por uno y añadimos el UID a cada registro
            querySnapShot.forEach(queryDocumentSnapshot => {
                registros[queryDocumentSnapshot.id] = queryDocumentSnapshot.data();
                registros[queryDocumentSnapshot.id].uid = queryDocumentSnapshot.id;
            });
            callback && callback(registros);
        })
        .catch(error => {
            // En caso de error, recibiremos un objeto Error { code: "", message: ""}, llamamos al fallback pasándole el motivo traducido a un String
            let motivo = {
                "invalid-argument": "No se pudo cargar el listado"
            }[error.code] || "["+error.code+"]: " + error.message;
            fallback && fallback(motivo);
        });
    }
*/