import React from 'react';
import { FirebaseContext } from '../Firebase';
import { Route, Switch, withRouter } from 'react-router-dom';

// Componentes
import ListaPerfiles from './ListaPerfiles';
import PerfilEquipo from './PerfilEquipo';
import PerfilUsuario from './PerfilUsuario';

// Estilos
import '../../estilos/perfiles.scss'


class Perfiles extends React.Component { 
	
	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;


	// ================================================================================================================================================================================================
	//	React
	// ================================================================================================================================================================================================

	constructor(props){ super(props);
		this.state = {
			listaPerfiles: { lista: {} }
		};
	}

	componentDidMount(){
		this.ListaPerfiles_Actualizar();
	}
	
	render(){
		
		return(
			<div id="Perfiles">
				<Switch>
					<Route exact path="/perfiles">
							
						<ListaPerfiles datos={this.state.listaPerfiles} usuarios={this.props.usuarios} />

					</Route>
					<Route exact path="/perfiles/equipo/:uid">
					
						<PerfilEquipo />
					
					</Route>
					<Route exact path="/perfiles/usuario/:uid">
					
						<PerfilUsuario />
					
					</Route>
				</Switch>
			</div>
		);
		
	}
//<PageNoticias cerrar={this.PageNoticias_Cerrar.bind(this)} />

	// ================================================================================================================================================================================================
	//	Lista Perfiles
	// ================================================================================================================================================================================================
	
	ListaPerfiles_Actualizar(){

		// Referencia a Firebase
		let api = this.context;

		// Solicitamos la lista de perfiles y la metemos en el State para que se actualice la tabla
		
		api.getPerfiles(perfiles => {
			this.setState({ listaPerfiles: { lista: perfiles } });
		});
		
	}

}

export default withRouter(Perfiles);
