import React from 'react';
import { FirebaseContext } from '../Firebase';
//import { Notification_Danger, Notification_Success } from '../Notification.js';


class PopupChats extends React.Component {

	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;

	// ================================================================================================================================================================================================
	//	REACT
	// ================================================================================================================================================================================================

    constructor(props){ super(props);
        
        // Inicializamos el State
		this.state = {
            esperando: "",
            huboCambios: false,
            usuario_uid: null,
            chat_uid: null
            //tipo: "individual",

        }

        // Cogemos ref del formulario para poder hacer la animación de apertura del formulario
        this.refAnimacion = React.createRef();

        // Conectamos los eventos con la instancia
        //this.onClickTipoIndividual = this.onClickTipoIndividual.bind(this);
        //this.onClickTipoChat = this.onClickTipoChat.bind(this);
        this.onClickUsuario = this.onClickUsuario.bind(this);
        this.onClickCrear = this.onClickCrear.bind(this);
        this.onClickCancelar = this.onClickCancelar.bind(this);
/*        
        this.onClickAceptar = this.onClickAceptar.bind(this);
        this.onClickEliminar = this.onClickEliminar.bind(this);
        this.onActualizarUsuario = this.onActualizarUsuario.bind(this);
        this.onChangeImagen = this.onChangeImagen.bind(this);
*/
    }
    
    componentDidMount(){
        
        // Animación de apertura del formulario
        this.Animate_Abrir();

    }

	render(){
        
        // Para saber si el registro es nuevo o estamos editando miramos si el objeto original tiene un UID
        // const nuevo = !this.chat.uid;

        // Para saber si estamos esperando a que cargue el objeto
        const esperando = this.state.esperando;
        
        // Lista completa de todos los usuarios para poder seleccionar con quién queremos hablar
        const usuarios = this.props.usuarios;

		return (
			<div className="PopupChats modal">
				<div className="modal-dialog" ref={this.refAnimacion}>
					<div className="modal-content">
				
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Nuevo Chat:
                            </h5>
                            <button type="button" className="close" onClick={this.onClickCancelar}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        {esperando?
                            <div className="modal-body">
                                <p className="text-center">{esperando}</p>
                                <div className="progress"><div className="progress-bar progress-bar-striped progress-bar-animated bg-warning" role="progressbar" style={{width: "100%"}}></div></div>
                            </div>
                        :
							<div className="modal-body">
                                <p>Seleccione la persona con la que desea hablar:</p>
                                <div className="usuarios">
                                    {Object.keys(usuarios).map(uid =>
                                        <div className={"usuario "+(uid===this.state.usuario_uid?"seleccionado":"")} key={uid} uid={uid} onClick={this.onClickUsuario} >
                                            <div className="foto" style={{backgroundImage: "url("+(usuarios[uid].imagen || "/img/usuario.jpg")+")"}} alt={usuarios[uid].nombre+" "+usuarios[uid].apellidos}></div>
                                            <span><strong>{usuarios[uid].usuario}</strong> <i>{usuarios[uid].nombre+" "+usuarios[uid].apellidos}</i></span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        }

                        <div className="modal-footer">
                            {!!this.state.usuario_uid && !esperando && <button type="button" className="btn btn-primary" onClick={this.onClickCrear}>Crear</button>}
                            <button type="button" className="btn btn-warning" onClick={this.onClickCancelar}>Cancelar</button>
                        </div>
                        
				    </div>
			    </div>
            </div>
		);
		
    }
    /*
<div>
<input type="button" className={"btn "+(this.state.tipo==="individual"?"btn-primary":"btn-secondary")} value="Individual" onClick={this.onClickTipoIndividual} />
&nbsp;
<input type="button" className={"btn "+(this.state.tipo==="grupo"?"btn-primary":"btn-secondary")} value="Grupo" onClick={this.onClickTipoGrupo} />
</div>    
                            {nuevo && !esperando && <button type="button" className="btn btn-primary" onClick={this.onClickCrear}>Crear</button>}
                            {!nuevo && !esperando && <button type="button" className="btn btn-primary" onClick={this.onClickAceptar}>Aceptar</button>}
                            <button type="button" className="btn btn-warning" onClick={this.onClickCancelar}>Cancelar</button>
                            {!nuevo && !esperando && <button type="button" className="btn btn-danger" onClick={this.onClickEliminar}>Eliminar</button>}
*/
	// ================================================================================================================================================================================================
	//	ANIMATE
	// ================================================================================================================================================================================================

    Animate_Abrir(){
        this.refAnimacion.current.classList.add("animated", "faster", "fadeInDown");
        this.refAnimacion.current.parentElement.classList.add("animated", "faster", "fadeIn");
    }

    Animate_Cerrar(){
        this.refAnimacion.current.classList.add("fadeOutUp");
        this.refAnimacion.current.parentElement.classList.add("fadeOut");
        // Al cerrar indicamos si hubo cambios en la lista de chats y si hay que cambiar a otro chat
        window.setTimeout( ()=>{ this.props.cerrar(this.state.huboCambios, this.state.chat_uid); }, 500 );
    }

	// ================================================================================================================================================================================================
	//	FORMULARIO
	// ================================================================================================================================================================================================

    //onClickTipoIndividual(evento) { evento.preventDefault(); this.setState({ tipo: "individual" }); }
    //onClickTipoGrupo(evento) { evento.preventDefault(); this.setState({ tipo: "grupo" }); }
    onClickUsuario(evento) { evento.preventDefault(); this.setState({ usuario_uid: evento.currentTarget.getAttribute("uid") }); }

    // ================================================================================================================================================================================================
	//	EVENTOS
	// ================================================================================================================================================================================================

    onClickCrear(){

        // Referencia a Firebase
        let api = this.context;

        // Miramos si alguno de los chats en los que participa el usuario es el mismo que quiere crear, porque entonces no lo crearemos
        let existe = null;
        Object.keys(this.props.chats).forEach(uid => {
            let tipo = this.props.chats[uid].tipo;
            let participantes = this.props.chats[uid].participantes;
            if(tipo==="individual" && participantes.includes(this.state.usuario_uid)) existe = this.props.chats[uid];
        });

        // Vemos si algún chat era exactamente el que el usuario quería
        if(existe) {
            // Si el chat ya existe, cerramos el POPUP indicando que no hubo cambios en la lista de chats y que queremos cambiar a ese chat
            this.setState({ huboCambios: false, chat_uid: existe },() => { this.Animate_Cerrar(); });
        } else {
            // Si el chat no existe, tenemos que crearlo
            this.setState({ esperando: "Creando Chat..." }, ()=>{
                api.newChatIndividual(this.props.usuario.uid, this.state.usuario_uid,
                    chat => { this.setState({ huboCambios: true, chat_uid: chat },() => { this.Animate_Cerrar(); }); },
                    mensaje => { /*Notification_Danger("Error al crear Usuario", mensaje);*/ this.Animate_Cerrar(); alert(mensaje); }
                );
            });
        }
        /*
        // Creamos un Usuario en la base de datos y recibiremos el UID con el que se guardó
        this.setState({ esperando: "Creando Chat..." }, ()=>{
            api.newChatIndividual(this.props.usuario.uid, this.state.usuario_uid,
                chat => { window.culo = chat; /*usuario.uid = nuevoUsuario.uid; this.usuario = usuario; this.setState({ actualizarTabla: true }); this.pasarUsuarioAlFormulario(this.usuario); },
                mensaje => { /*Notification_Danger("Error al crear Usuario", mensaje); this.Animate_Cerrar(false); console.log(mensaje); }
            );
        });
*/
    }
/*
	onClickAceptar(){

        // Validamos el formulario y obtenemos la lista de errores para poder mostrarlos
        let errores = this.validarFormulario(this.formUsuario);
        errores.forEach(mensaje => { Notification_Danger("Error en Formulario", mensaje); } );
        // Si hubo algún error, salimos
        if(errores.length>0) return;

        // Referencia a Firebase
        let api = this.context;

        // Sacamos el objeto Usuario del formulario
        let usuario = this.cogerUsuarioDelFormulario();

        // Guardamos el Usuario en la base de datos y cerramos el formulario
        this.setState({ esperando: "Guardando Usuario..." }, ()=>{
            api.setUser(this.usuario.uid, usuario,
                x => { Notification_Success("Usuario guardado","El Usuario se ha guardado correctamente"); this.Animate_Cerrar(true); },
                mensaje => { Notification_Danger("Error al guardar Usuario", mensaje); }
            );
        });

	}
	*/
    onClickCancelar(){
        // Cerramos el formulario indicando que no hubo cambios
        this.Animate_Cerrar(false);
	}
/*
    onClickEliminar(){
        if(window.confirm("¿Seguro que desea eliminar este usuario?")){

            // Referencia a Firebase
            let api = this.context;

            // Eliminamos el usuario
            this.setState({ esperando: "Eliminando Usuario..." }, ()=>{
                api.delUser(this.usuario.uid,
                    x => { Notification_Success("Usuario eliminado","El Usuario se ha eliminado correctamente"); this.Animate_Cerrar(true); },
                    mensaje => { Notification_Danger("Error al eliminar Usuario", mensaje); }
                );
            });

        }
	}


	onChangeImagen(evento){ evento.preventDefault();
	/*
		// Referencia a Firebase
		let api = this.context; 

		// Referencia a Storage
		var storage = api.storage.ref();
		
		// Archivo que queremos subir
		var archivo = this.refArchivo.current.files[0];
		
		// Subimos el archivo usando como nombre el id del usuario para sobreescribir el antiguo. Indicamos que los metadatos son 'image/jpeg'
		var task = storage.child('usuarios/' + this.state.usuario.id +  '.jpg').put(archivo, { contentType: 'image/jpeg' });

		// Establecemos el evento 'state_changed' para saber cómo va y cuándo termina
		task.on('state_changed', (info) => {
			// Cuando cambie el porcentaje de carga, lo actualizamos en el State
			let porcentajeImagen = (info.bytesTransferred / info.totalBytes) * 100;
			this.setState({	porcentajeImagen: porcentajeImagen.toFixed(2)});
		}, error => {
            // En caso de error mostramos un alert
            // TODO: Mostrar una notificación
			alert("error: " + JSON.stringify(error));
		}, () => {
            // Si todo va bien, obtenemos la URL de descarga de la imagen para guardarla como dato de usuario
			task.snapshot.ref.getDownloadURL().then(downloadURL => {
                // Metemos la URL en el State
				let usuario = this.state.usuario;
				usuario.prof_picture = downloadURL;
				this.setState({ usuario: usuario });
				// Tenemos que actualizar el valor de la imagen en la base de datos ya, porque ya hemos sobreescrito la imagen antigua
				api.setUser(this.state.usuario.id,{ prof_picture: downloadURL });
				// TODO: lanzar una notificación
			});
		});
    
    }
    */
	// ================================================================================================================================================================================================
	//	PARA CREAR UN MODULO!!
	// ================================================================================================================================================================================================


    referenciasFormulario(formulario){

        // Cogemos todas las referencias que aparezcan, en el Array de referencias, en las keys de modificaciones y las keys de validaciones
        let referencias = {};
        if(formulario.referencias) formulario.referencias.forEach(refElemento => { referencias[refElemento] = true; });
        Object.keys(formulario.modificaciones).forEach(refElemento => { referencias[refElemento] = true; });
        Object.keys(formulario.validaciones).forEach(refElemento => { referencias[refElemento] = true; });

        // Ahora tenemos en referencias todas las referencias que nos pueden hacer falta (sin duplicados), creamos la referencias de React
        Object.keys(referencias).forEach(refElemento => { this[refElemento] = React.createRef(); });

    }
    
    modificarFormulario(formulario){
        Object.keys(formulario.modificaciones).forEach(refElemento => {

            if(this[refElemento]){
                let elemento = this[refElemento].current;

                if(elemento){
                    let modificaciones = formulario.modificaciones[refElemento];

                    // Comprobamos si hay que limitar el número de caracteres
                    if(modificaciones.lim) elemento.value = elemento.value.substring(0,modificaciones.lim);

                    // Comprobamos si hay una lista de caracteres permitidos
                    if(modificaciones.car) elemento.value = elemento.value.split("").filter( c => modificaciones.car.indexOf(c)>=0 ).join("");
                }
            }
        });
    }

    validarFormulario(formulario){ let errores = [];

        Object.keys(formulario.validaciones).forEach(refElemento => {
            if(this[refElemento] && this[refElemento].current){

                let elemento = this[refElemento].current;
                if(elemento){

                    let validaciones = formulario.validaciones[refElemento];
                    let hayError = false;

                    // Comprobamos si hay que rellenar un mínimo de caracteres
                    if(validaciones.min && elemento.value.length<validaciones.min.valor) { errores.push(validaciones.min.mensaje); hayError = true; }

                    // Comprobamos si no se puede superar un máximo de caracteres
                    if(validaciones.max && elemento.value.length>validaciones.max.valor) { errores.push(validaciones.max.mensaje); hayError = true; }

                    // Comprobamos si se ha usado algún caracter que no esté en la lista de permitidos
                    if(validaciones.per && elemento.value !== elemento.value.split("").filter( c => validaciones.per.valor.indexOf(c)>=0 ).join("")) { errores.push(validaciones.per.mensaje); hayError = true; }

                    // Comprobamos si hay un valor numérico inferior
                    if(validaciones.inf && +elemento.value<validaciones.inf.valor) { errores.push(validaciones.inf.mensaje); hayError = true; }

                    // Comprobamos si hay un valor numérico superior
                    if(validaciones.sup && +elemento.value>validaciones.sup.valor) { errores.push(validaciones.sup.mensaje); hayError = true; }
            
                    // Si hemos detectado algún error en este elemento, le añadimos la classError indicada
                    if(hayError) { elemento.classList.add("is-invalid"); } else { elemento.classList.remove("is-invalid"); }
                }
            }
        });
        
        return errores;
    }

}

export default PopupChats;