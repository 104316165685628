import React from 'react';
import { FirebaseContext } from '../Firebase';
import { withRouter } from 'react-router-dom';

class Chat extends React.Component {
		
	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;
	
    // Desvinculador de los mensajes del chat
	stopChatMensajes = null;
	
	// Referencias
	refMensaje = React.createRef();
	refFinal = React.createRef();

	// ================================================================================================================================================================================================
	//	React
	// ================================================================================================================================================================================================

	
	constructor(props){ super(props);

		// Inicializamos el State
		this.state = {
			chat: null,
			mensajes: []
		}

        // Vinculamos los eventos con la instancia
    //    this.onClickEditarChat = this.onClickEditarChat.bind(this);
        this.onClickCargarAntiguos = this.onClickCargarAntiguos.bind(this);
		this.onKeyDownEnviar = this.onKeyDownEnviar.bind(this);
	//this.onClickChat = this.onClickChat.bind(this);
//    this.PopupChats_Cerrar = this.PopupChats_Cerrar.bind(this);

	}

	componentDidMount(){

		// Referencia a Firebase
		let api = this.context;

        // Para pedir la lista de mensajes tenemos que darle una fecha a partir de la cual se devolverá un número de registros
		let timestamp = new Date();
		
		// Cogemos el UID del chat que queremos ver
		const uidChat = this.props.location.pathname.replace("/chats/","");
		
		// Si estabamos escuchando algo, dejamos de hacerlo
		if(this.stopChatMensajes) { this.stopChatMensajes(); }

		// Obtenemos los datos del chat a partir del UID indicado en la URL
		api.getChat(uidChat, chat => {

			// Si todo va bien, metemos el objeto Chat en el State
			this.setState({ chat: chat });
			
			// Pedimos los últimos mensajes que hubiese en el chat
			api.getChatMensajes(uidChat, timestamp,
				mensajes => { this.setState({ mensajes: mensajes }); }
			);

			// Y ahora, nos quedamos escuchando si alguien pone algún mensaje nuevo en este chat
			this.stopChatMensajes = api.startChatMensajes(uidChat, timestamp,
				mensajes => { this.setState({ mensajes: [...this.state.mensajes, ...mensajes] },()=>{ this.refFinal.current.scrollIntoView({ behavior: "smooth" }); }); }
			);

		});

	}
	
	render(){
		
		return(

			<div id="Chat" className="container">
                <div className="row">

					<div className="col-12">
						<div className="Mensajes">
							<input type="button" className="btn btn-secondary btn-sm btn-block mb-3" onClick={this.onClickCargarAntiguos} value="Cargar mensajes antiguos" />
							<ul>
								{this.state.mensajes.length?
									this.state.mensajes.map(mensaje => this.renderMensaje(mensaje))
								:
									<span>No hay mensajes</span>
								}
								<li className="final" ref={this.refFinal}></li>
							</ul>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="escribir componente">
							<textarea ref={this.refMensaje} onKeyDown={this.onKeyDownEnviar} placeholder="Escribe un mensaje..."></textarea>
						</div>
					</div>
				</div>
				
			</div>
		);

	}

	// ================================================================================================================================================================================================
	//	RENDERS
	// ================================================================================================================================================================================================

	renderMensaje(mensaje){
		
		// Cogemos los datos que nos hacen falta
		let uid = mensaje.uid;
        let uidUsuario = mensaje.usuario;
		let fecha = mensaje.fecha.toDate();
		
        // Vemos si el mensaje pertenece al usuario logueado o a cualquier otro usuario
        if(uidUsuario === this.props.usuario.uid){
            // Usuario Logueado
            return (
                <li key={uid} className={"mensaje text-right"}>
                    {mensaje.texto}<br />
                    <small>{this.parseFecha(fecha)}</small>
                </li>
            );
        } else {
            // Otro Usuario
            return (
                <li key={uid} className={"mensaje text-left"}>
                    <strong>{this.props.usuarios[uidUsuario].usuario}</strong>
                    <p>{mensaje.texto}</p>
                    <small>{this.parseFecha(fecha)}</small>
                </li>
            );
        }
	}
	
    // ================================================================================================================================================================================================
    //	EVENTOS
    // ================================================================================================================================================================================================

	onKeyDownEnviar(evento){ if(evento.key==="Enter") { evento.preventDefault(); this.onClickEnviar(evento); } }

	onClickEnviar(evento){ evento.preventDefault();
		
		// Referencia a Firebase
		let api = this.context;
		
		// Cogemos y borramos el mensaje del textbox
		let mensaje = this.refMensaje.current.value;
		this.refMensaje.current.value = "";

		// Vemos quién es el que escribe el mensaje
		let usuario = this.props.usuario.uid;
		
		// Vemos en qué Chat hay que añadir el mensaje
		let chat = this.state.chat.uid;

		// Guardamos el mensaje en la base de datos
		if(mensaje && usuario && chat){
			api.newChatMensajes(chat, {
				usuario: usuario,
				texto: mensaje,
				fecha: new Date()
			});
        }
        
        // Lanzamos una PUSH a todos los participantes excepto al propio usuario
		let participantes = this.state.chat.participantes;

		// Filtramos el propio usuario de la lista de participantes
        participantes.filter( participante => participante!==this.props.usuario.uid ).forEach(

			// Y para cada participante que quede en el Array, lanzamos una notificación
            participante => {

                // Vemos si el participante tiene token para envio de notificaciones PUSH
                if(this.props.usuarios[participante].token){
                    
					// Argumentos: Destinatario, Título, Texto, Icono y Enlace
					// Añadimos el UID del Chat como TAG para que se agrupen las notificaciones del mismo chat
                    api.sendPush(
                        this.props.usuarios[participante].token,
                        "Nuevo mensaje de " + this.props.usuario.nombre + " " + this.props.usuario.apellidos,
                        mensaje,
                        "https://gamify.ctingenieros.es/img/twemoji/1f4e8.png",
                        "https://gamify.ctingenieros.es/chats/" + chat,
                        chat
					);
					
                }                
            }
        );
		
	}

    onClickCargarAntiguos(evento){ evento.preventDefault();
    
		// Referencia a Firebase
		let api = this.context;

        // Cogemos la fecha del primer mensaje que aparece en la lista
        let timestamp = this.state.mensajes[0].fecha.toDate();

        // Cargamos los siguientes mensajes anteriores a la fecha indicada
        api.getChatMensajes(this.state.chat.uid, timestamp,
            mensajes => { mensajes.pop(); this.setState({ mensajes: [...mensajes, ...this.state.mensajes] }); }
        );

    }



    // ================================================================================================================================================================================================
    //	FUNCIONES
    // ================================================================================================================================================================================================

	parseFecha(fecha){
		let txtFecha = ("00" + fecha.getDate()).slice(-2) + "/" + ("00" + (fecha.getMonth()+1)).slice(-2) + "/" + fecha.getFullYear();
		let txtHora = fecha.getHours() +  ":" + ("00"+fecha.getMinutes()).slice(-2) + ":" + ("00"+fecha.getSeconds()).slice(-2);
		return  txtFecha + " " + txtHora ;
	}

}

export default withRouter(Chat);