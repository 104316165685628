import React from 'react';
import { FirebaseContext } from '../Firebase';
import { Route, Switch, withRouter } from 'react-router-dom';

// Componentes
import ListaNoticias from './ListaNoticias';
import Noticia from './Noticia';

// Estilos
import '../../estilos/noticias.scss'


class Noticias extends React.Component {
	
	// Cogemos referencia a Firebase del Context
	static contextType = FirebaseContext;


	// ================================================================================================================================================================================================
	//	React
	// ================================================================================================================================================================================================

	constructor(props){ super(props);
		this.state = { listaNoticias: { lista: {} }  };
	}

	componentDidMount(){
		this.ListaNoticias_Actualizar();
	}
	
	render(){
		
		return(
			<div id="Noticias">
				<Switch>
					<Route exact path="/noticias">
							
						<ListaNoticias datos={this.state.listaNoticias} />

					</Route>
					<Route exact path="/noticias/:uid">
					
						<Noticia />
					
					</Route>
				</Switch>
			</div>
		);
		
	}
//<PageNoticias cerrar={this.PageNoticias_Cerrar.bind(this)} />

	// ================================================================================================================================================================================================
	//	Lista Noticias
	// ================================================================================================================================================================================================
	
	ListaNoticias_Actualizar(){

		// Referencia a Firebase
		let api = this.context;

		// Solicitamos la lista de noticias y la metemos en el State para que se actualice la tabla
		api.getNoticias(function(noticias){
			this.setState({ listaNoticias: { lista: noticias } });
		}.bind(this),"fecha;desc");

	}

}

export default withRouter(Noticias);
