import React from 'react';
import { store } from 'react-notifications-component';

// ====================================================================================================================================================================================================
//  CONFIGURACIÓN BASE
// ====================================================================================================================================================================================================

const notification_base = {
    insert: "bottom",
    container: "bottom-right",
    animationIn: ["animated", "fadeIn"],
    animationOut: ["animated", "fadeOut"],
    dismiss: { duration: 5000, onScreen: true }    
}

// ====================================================================================================================================================================================================
//  NOTIFICACIONES DE LOGIN
// ====================================================================================================================================================================================================

/**
* Lanza la notificación de que el usuario se ha logueado correctamente
* @param {String} email El email del usuario que se ha logueado
*/
export const Notification_LoginOk = (email) => {
    store.addNotification({
        type: "success",
        title: "Login correcto",
        message: "Bienvenido "+email+"!",
        ...notification_base
    });
};

/**
* Lanza la notificación de que el usuario ha fallado al loguearse
* @param {String} mensaje Mensaje de error lanzado por la API
*/
export const Notification_LoginError = (mensaje) => {
    // Incluimos el mensaje de error
    store.addNotification({
        type: "danger",
        title: "Error en Login",
        message: mensaje,
        ...notification_base
    });
};

/**
* Lanza la notificación de que se ha enviado el mail de reseteo de password
* @param {String} email El email del usuario que ha solicitado el reseteo
*/
export const Notification_ResetOk = (email) => {
    store.addNotification({
        type: "success",
        title: "Password Reseteado",
        message: "Se le ha enviado un mail a la dirección "+email,
        ...notification_base
    });
};

/**
* Lanza la notificación de que el usuario ha fallado al resetear su password
* @param {String} mensaje Mensaje de error lanzado por la API
*/
export const Notification_ResetError = (mensaje) => {
    // Incluimos el mensaje de error
    store.addNotification({
        type: "danger",
        title: "Error en Reseteo de Password",
        message: mensaje,
        ...notification_base
    });
};

/**
* Lanza la notificación de que el usuario se ha deslogueado correctamente
*/
export const Notification_LogoutOk = (email) => {
    store.addNotification({
        type: "success",
        title: "Sesión cerrada",
        message: "La sesión se ha cerrado correctamente",
        ...notification_base
    });
};


// ====================================================================================================================================================================================================
//  NOTIFICACIONES DE USUARIOS
// ====================================================================================================================================================================================================

/**
* Lanza la notificación de que no se ha podido actualizar la tabla de usuarios
*/
export const Notification_UsuariosError = (mensaje) => {
    // No vamos a usar el mensaje de error en la notificación
    console.log("UsuariosError: "+mensaje);
    store.addNotification({
        type: "danger",
        title: "Error base de datos",
        message: "No se ha podido actualizar la tabla de Usuarios",
        ...notification_base
    });
};

/**
* Lanza la notificación de se ha actualizado el saldo de pegatinas
*/
export const Notification_UsuarioSaldo = () => {
    store.addNotification({
        type: "success", 
        title: "Saldo de Pegatinas actualizado",
        message: "Su saldo de pegatinas es ahora de 50 puntos",
        ...notification_base
    });
};

// ====================================================================================================================================================================================================
//  NOTIFICACIONES DE PEGATINAS
// ====================================================================================================================================================================================================

/**
* Lanza la notificación de se ha entregado una pegatina a un usuario
*/
export const Notification_PegatinaOk = (nombreUsuario) => {
    // Incluimos el nombre de la persona a la que le hemos dado la pegatina en el mensaje
    store.addNotification({
        type: "success",
        title: "Pegatina enviada!",
        message: "Seguro que "+nombreUsuario+" estará feliz de recibirla.",
        ...notification_base
    });
};

/**
* Lanza la notificación de aviso de que algo no está totalmente correcto
*/
export const Notification_PegatinaAviso = (mensaje) => {
    // Incluimos el mensaje que indica el aviso
    store.addNotification({
        type: "warning",
        title: "No se puede enviar la pegatina",
        message: mensaje,
        ...notification_base
    });
};

/**
* Lanza la notificación de que no se ha podido entregar una pegatina a un usuario
*/
export const Notification_PegatinaError = (nombreUsuario) => {
    // Incluimos el nombre de la persona a la que le hemos intentado dar la pegatina en el mensaje
    store.addNotification({
        type: "danger",
        title: "Error al enviar Pegatina",
        message: "Atención, "+nombreUsuario+" no ha podido recibirla.",
        ...notification_base
    });

};

/**
* Lanza una notificación con la información sobre una pegatina
*/
export const Notification_PegatinaVer = (pegatina) => {
    // Incluimos el nombre de la persona a la que le hemos intentado dar la pegatina en el mensaje
    store.addNotification({
        type: "info",
        title: "Pegatina",
        content: <Pegatina pegatina={pegatina} />,
        ...notification_base
    });

};

function Pegatina(props) {
    const fecha = new Date(props.pegatina.fecha.seconds*1000);
    let fechaString = ("00"+fecha.getDate()).slice(-2) + "/" + ("00"+(fecha.getMonth()+1)).slice(-2) + "/" + fecha.getFullYear();
    fechaString += " " + ("00"+(fecha.getHours()+1)).slice(-2) + ":" + ("00"+(fecha.getMinutes()+1)).slice(-2) + ":" + ("00"+(fecha.getSeconds()+1)).slice(-2);
    return (
        <div style={{ background: "white", width: "100%", padding: "10px" }}>
            <div><small>Entregada el {fechaString}</small></div>
            <img src={"./img/twemoji/"+props.pegatina.codigo+".png"} style={{ float: "left", width: "40px", margin: "4px 10px" }} alt={props.pegatina.codigo} />
            <div>De: {props.pegatina.de_usuario.usuario}</div>
            <div>Para: {props.pegatina.para_usuario.usuario}</div>
            <div><small>{props.pegatina.comentario}</small></div>
        </div>
    )
  }



  
// ====================================================================================================================================================================================================
//  NOTIFICACIONES DE CONFIGURACIÓN
// ====================================================================================================================================================================================================

/**
* Lanza la notificación de se ha guardado la configuración con éxito
*/
export const Notification_ConfiguracionOk = () => {
    store.addNotification({
        type: "success",
        title: "Configuración guardada",
        message: "Se han guardado todos los cambios.",
        ...notification_base
    });
};

/**
* Lanza la notificación de que hubo algún problema al guardar la configuración
*/
export const Notification_ConfiguracionError = (mensaje) => {
    store.addNotification({
        type: "danger",
        title: "Error en la configuración",
        message: mensaje,
        ...notification_base
    });
};

/**
* Lanza la notificación de se ha guardado la imagen de perfil en la configuración con éxito
*/
export const Notification_ConfiguracionImagenOk = () => {
    store.addNotification({
        type: "success",
        title: "Imagen actualizada",
        message: "Se actualizado la foto de perfil.",
        ...notification_base
    });
};

/**
* Lanza la notificación de que hubo algún problema al guardar la imagen de perfil en la configuración
*/
export const Notification_ConfiguracionImagenError = (mensaje) => {
    store.addNotification({
        type: "danger",
        title: "Error al subir la imagen",
        message: mensaje,
        ...notification_base
    });
};