import React from 'react';
import { Notification_PegatinaVer } from '../Notification';


class Tablon extends React.Component {

	// ================================================================================================================================================================================================
	//	REACT
	// ================================================================================================================================================================================================

	constructor(props) { super(props);

        // Inicializamos el state
        this.state = { ver: "recibidas" };

        // Vinculamos los eventos con la instancia
        this.onClickVer = this.onClickVer.bind(this);
        this.onClickPegatina = this.onClickPegatina.bind(this);

	}

    render() {
        
        // Cogemos el UID del usuario que está logueado
        let uidUsuarioLogueado = this.props.usuario.uid;

        // Filtramos las pegatinas para quedarnos sólo las que nos interesan
        let pegatinas = this.props.pegatinas || {};
        let lista = Object.keys(pegatinas).filter(uidPegatina => {
            let uidUsuario = (this.state.ver==="recibidas")? pegatinas[uidPegatina].para : pegatinas[uidPegatina].de;
            return (uidUsuario===uidUsuarioLogueado);
        });
        
		
        return (
    
				<div className="col-12">
					<div className="Tablon componente">
						<h3>
                            Pegatinas:
                            <button type="button" className={"btn btn-light btn-sm nofocus "+(this.state.ver==="recibidas"?"active":"")} ver="recibidas" onClick={this.onClickVer}>Recibidas</button>
                            <button type="button" className={"btn btn-light btn-sm nofocus "+(this.state.ver==="enviadas"?"active":"")} ver="enviadas" onClick={this.onClickVer}>Enviadas</button>
                        </h3>

                        {lista.length>0?
                            <div className="text-center">
                                {lista.map(uid =>
                                    <img key={uid} uid={uid} src={"./img/twemoji/"+pegatinas[uid].codigo+".png"} className="icono" alt={"pegatina-"+pegatinas[uid].codigo} onClick={this.onClickPegatina} />
                                )}
                            </div>
                        :
                            <div className="text-center">No hay pegatinas todavía</div>
                        }

					</div>
				</div>
	  
        );
    }
	
	// ================================================================================================================================================================================================
	//	EVENTOS
	// ================================================================================================================================================================================================

	onClickVer(evento){ evento.preventDefault();
		
        // Cambiamos a la vista que nos hayan dicho
        let ver = evento.currentTarget.getAttribute("ver");
        this.setState({ ver: ver });
		
	}
    
    onClickPegatina(evento){ evento.preventDefault();
    
        // Mostramos la información de la pegatina
        let uid = evento.currentTarget.getAttribute("uid");
        let pegatina = this.props.pegatinas[uid];

        // Creamos una notificación con la información
        Notification_PegatinaVer(pegatina);

    }
	
}

export default Tablon;
