import React from 'react';

// Estilos
import '../../estilos/premios.scss';


class Premios extends React.Component {

    // ================================================================================================================================================================================================
    //  REACT
    // ================================================================================================================================================================================================

	render() {
		return(
			<div id="Premios" className="container">
				<div className="row">
					
					<div className="col-12 col-sm-6 col-md-8 col-lg-9">
						<div className="componente">
							<h3>Premios en CT Gamify</h3>
							<p>Usar la aplicación de gamify tiene sus ventajas, entre ellas que puedes ganar CT Coins y canjearlas por interesantes premios.</p>
							<p>Las CT Coins se pueden ganar participando en las actividades de la aplicación como por ejemplo, las pegatinas, encuestas, juegos, concursos...</p>
							<p>También puedes darnos sugerencias sobre cómo mejorar la aplicación y también tendrás tu recompensa.</p>
						</div>
					</div> 

					<div className="col-12 col-sm-6 col-md-4 col-lg-3">
						<div className="componente">
							<h3>CT Coins</h3>
							<div className="text-center">
								<div>Tienes actualmente</div>
								<div className="ctCoins">
									<span className="numero">{this.props.login.ctcoins || 0}$</span>
									<i className="fas fa-circle fa-3x"></i>
								</div>
							</div>
						</div>
					</div> 
					
					<div className="col-12">
						<div className="componente">
							<h3>Lista de premios</h3>
							<p>Esta es la lista de premios que puedes conseguir con tus CT Coins. Aunque la lista es larga, siempre estamos a la búsqueda de nuevas formas de recompensarte por estar con nosotros y participar con nuestras actividades.</p>
							<p>Pásate de vez en cuando por aquí para no perderte ninguna novedad, y por supuesto, siempre puedes darnos alguna buena idea ;)</p>
							
							<div className="listaPremios text-center">


								<div className="premio">
									<h4>Auriculares (5 CT Coins)</h4>
									<img src="/img/premios/auriculares.PNG" alt="Auriculares"/>
									<div className="descripcion">Auriculares balncos con cuatro almohadillas extra (2 azules y 2 blancas). Presentado en cajita blanca con tapa tranparente. Medidas: Largo del cordón (incluyendo las almohadillas) es de 100cm.</div>
								</div>

								<div className="premio">
									<h4>Taza (8 CT Coins)</h4>
									<img src="/img/premios/taza.PNG" alt="Taza"/>
									<div className="descripcion">Taza de porcelana de 325 ml. Apta para la sublimación y su limpieza en el lavavajillas.</div>
								</div>

								<div className="premio">
									<h4>Pendrive 8Gb (10 CT Coins)</h4>
									<img src="/img/premios/pendrive.PNG" alt="Pendrive"/>
									<div className="descripcion">Mini memoria USB de 8GB de capacidad, con mecanismo giratorio y acabado en aluminio brillante.
									Presentada en estuche individual. Medida y Peso Articulo 1,2 x 3,3 x 0,6 cm | 5 gr. Material Aluminio, presentación individual.</div>
								</div>

								<div className="premio">
									<h4>Agenda (12 CT Coins)</h4>
									<img src="/img/premios/agenda.PNG" alt="Agenda"/>
									<div className="descripcion">Bloc de notas, portada en cartón y bolsillo.</div>
								</div>

								<div className="premio">
									<h4>Gorra (15 CT Coins)</h4>
									<img src="/img/premios/gorra.PNG" alt="Gorra"/>
									<div className="descripcion">Gorra seis paneles con sándwich en contraste, 6 agujeros de ventilación bordados en
									contraste,. 6 líneas pespunte en visera. Cinta antisudoral en el interior, Cierre velcro. Material 100% algodón.</div>
								</div>

								<div className="premio">
									<h4>Altavoz Bluetooth (19 CT Coins)</h4>
									<img src="/img/premios/altavoz.PNG" alt="Altavoz"/>
									<div className="descripcion">Compacto altavoz de conexion bluetooth, de diseño minimalista y conexion bluetooth 3.0, ofrece 3W de potencia y entrada auxiliar de linea, mediante conexion jack de 3,5mm.
									Compatible con iOS y Android, incluye cabel de carga mini USB, cable auxiliar jack de 3,5mm y viene presentado en atractiva caja de diseño. Medida 5 x 5,4 x 5 cm </div>
								</div>

								<div className="premio">
									<h4>Batería Externa (22 CT Coins)</h4>
									<img src="/img/premios/bateria.PNG" alt="Batería"/>
									<div className="descripcion">Power Bank con batería de litio. Con capacidad de 2600mAh, input de 5V/1000mA y output de 5V/100mA. Incluye cable USB/micro, adaptable a la mayoría de móviles, tablets, cámaras digitales, videoconsola portátil, GPS y MP3.</div>
								</div>

								<div className="premio">
									<h4>Camiseta (25 CT Coins)</h4>
									<img src="/img/premios/camiseta.PNG" alt="Camiseta"/>
									<div className="descripcion">Camiseta técnica de manga corta ranglán. Escote redondeado con cubrecosturas. Cortes laterales con remallado al tono.
									Size S to XXXL. Material 100% poliéster, polar, 300 g/m².</div>
								</div>

								<div className="premio">
									<h4>Mochila (28 CT Coins)</h4>
									<img src="/img/premios/mochila.PNG" alt="Mochila"/>
									<div className="descripcion">Bolsillo Acolchado para Portátil. Parte Trasera y Cintas Acolchadas. Mochila de resistente nylon 900D de sobrio diseño con cierre de cremallera, bolsillo frontal y asa reforzada. Con acolchado en espalda y
									cintas de hombros, incluye compartimento interior acolchado para ordenador portátil de hasta 15”. Medida y Peso Articulo: 30 x 41 x 14 cm | 510 gr. Material Nylon 900D</div>
								</div>

								<div className="premio">
									<h4>Impermeable (32 CT Coins)</h4>
									<img src="/img/premios/impermeable.PNG" alt="Impermeable"/>
									<div className="descripcion">Parka con Forro Acolchado. Forro: Acolchado de Poliéster. Estilo Abertura en Cremallera con Trabilla, Velcro y Corchetes Ocultos, Puños con Trabilla y Velcro, Capucha con Cordón,
									Cuello Doblado, dos Bolsillos Internos, dos Bolsillos Exteriores. Revestimiento del Cuello en Lana Negra. Size XS to XXXL. Material 100% Poliéster - Oxford</div>
								</div>

								<div className="premio">
									<h4>Polar (35 CT Coins)</h4>
									<img src="/img/premios/polar.PNG" alt="Polar"/>
									<div className="descripcion">Chaqueta polar, con cuello alto forrado y cremallera inyectada al tono. Bolsillos laterales con cremallera, puños elásticos y bajo con ajustadores laterales
									Size S to XXXXL. Material 100% poliéster piqué, 150g/m²</div>
								</div>

							</div>

						</div>
					</div> 
					
					
					
				</div>
			</div>
		);
	}
	
  
  
}

export default Premios;