import React from 'react';

class Ranking extends React.Component {

	// ================================================================================================================================================================================================
	//	REACT
	// ================================================================================================================================================================================================

	constructor(props) { super(props);

        // Inicializamos el state
        this.state = { ver: "queridos" };

        // Vinculamos los eventos con la instancia
        this.onClickVer = this.onClickVer.bind(this);

    }
    
    render() {

        // Cogemos el UID del usuario que está logueado
        let uidUsuarioLogueado = this.props.usuario.uid;

        // Lista completa de usuarios
        let usuarios = this.props.usuarios;

        // Lista completa de pegatinas
        let pegatinas = this.props.pegatinas || {};

        // Lista de UIDs de todas las pegatinas del mes
        let uids = Object.keys(pegatinas);

        // Creamos un objeto con la suma total de puntos por usuario, será del tipo { uidUsuario: { puntos: x, usuario: usuario}, ... }
        let usuarioPuntos = {};
        uids.forEach(uid => {
				
            // Cogemos el UID del usuario al que va dirigida la pegatina o el UID del usuario que envía la pegatina según queramos ver los más queridos o los más generosos (respectivamente)
            let uidUsuario = (this.state.ver==="queridos")? pegatinas[uid].para : pegatinas[uid].de ;
				
            // Cogemos los puntos asignados a la pegatina
            let puntos = pegatinas[uid].puntos;
			
            // Vemos si el UID del usuario está ya en el objeto de usuarioPuntos
            if(usuarioPuntos[uidUsuario]) {
                // Si está, le sumamos los puntos
                usuarioPuntos[uidUsuario].puntos += puntos;
            } else {
                // Y si no está lo añadimos con esos puntos
                usuarioPuntos[uidUsuario] = {};
                usuarioPuntos[uidUsuario].puntos = puntos;
                // Y metemos los datos del usuario
                if(!usuarios[uidUsuario]) {
                    window.culo=JSON.stringify(usuarios);
                    console.log(JSON.stringify(usuarios));
                    console.log(JSON.stringify(uidUsuario));
                    console.log(usuarios[uidUsuario]);
                    alert("NO EXISTE: ["+uidUsuario+"]");

                }
                usuarioPuntos[uidUsuario].usuario = usuarios[uidUsuario];
            }
				
        });

        // Ahora que sabemos los puntos de cada usuario y sus datos, convertimos el objeto en Array y ordenamos según sus puntos
        let ranking = [];
        Object.keys(usuarioPuntos).forEach(x => { ranking.push(usuarioPuntos[x]); });
        ranking.sort(function(a,b){ return b.puntos-a.puntos; });

        // NOTA: El primer día del mes, es posible que no haya ninguna pegatina en el sistema, hay que tener cuidado con eso!

		// Separamos al primero de la lista de los siguientes para ponerlo destacado
		let primero = null;
		let siguientes = [];
        // Copiamos el Array de datos
        siguientes = [...ranking];
        // Sacamos el primer elemento
        if(siguientes.length>0) primero = siguientes.shift();

        // Cogemos la imagen del primer usuario
        let imagenPrimero = "/img/usuario.jpg";
        if(primero && primero.usuario && primero.usuario.imagen) imagenPrimero = primero.usuario.imagen;

        return (
    
			<React.Fragment>
				<div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
								
					<div className="Ranking componente">
						
						<h3>
                            Ranking:<br />
                            <button type="button" className={"btn btn-light btn-sm nofocus "+(this.state.ver==="queridos"?"active":"")} ver="queridos" onClick={this.onClickVer}>Queridos</button>
                            <button type="button" className={"btn btn-light btn-sm nofocus "+(this.state.ver==="generosos"?"active":"")} ver="generosos" onClick={this.onClickVer}>Generosos</button>
                        </h3>
						
                        {primero?
                        
                            <React.Fragment>
                                <div className="ganador">
                                    <div className="foto" style={{backgroundImage: "url("+imagenPrimero+")"}}></div>
                                    <small>{primero.puntos}</small>
                                    <strong>{primero.usuario.usuario}</strong>
                                </div>
                                
                                <ul className="siguientes">
                                    {siguientes.map(usuario => {
                                        
                                        // Esto no debería pasar, pero si hay un usuario null, nos lo saltamos
                                        if(!usuario.usuario) return null;

                                        // Cogemos la imagen del usuario
                                        let imagen = "/img/usuario.jpg";
                                        if(usuario.usuario && usuario.usuario.imagen) imagen = usuario.usuario.imagen;

                                        return (
                                            <li key={usuario.usuario.usuario}>
                                                <small>{usuario.puntos}</small>
                                                <span className="ct-usuario-h">
                                                    <div className="foto" style={{backgroundImage: "url("+imagen+")"}}></div>
                                                    <span className={usuario.usuario.uid===uidUsuarioLogueado?"txtNegro":""}>{usuario.usuario.usuario}</span>
                                                </span>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </React.Fragment>

                        :
                        
                            <div className="text-center">No hay pegatinas todavía</div>
                            
                        }
						
					</div>

				</div>
			</React.Fragment>

        );
    }

	// ================================================================================================================================================================================================
	//	EVENTOS
	// ================================================================================================================================================================================================

	onClickVer(evento){ evento.preventDefault();
		
        // Cambiamos a la vista que nos hayan dicho
        let ver = evento.currentTarget.getAttribute("ver");
        this.setState({ ver: ver });
		
    }
    
}

export default Ranking;
