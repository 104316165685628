import React, {Component} from 'react';
import { NavLink } from 'react-router-dom';

class Cumples extends Component {

    // ================================================================================================================================================================================================
    //  REACT
    // ================================================================================================================================================================================================

    constructor(props){ super(props);

		// Guardaremos aquí los cumpleaños más cercanos a la fecha actual
		let cumplesCercanos = [];

		if(this.props.usuarios){
			
			// Vamos a obtener los cumpleaños más cercanos
			cumplesCercanos = Object.keys(this.props.usuarios)
			// Recorremos toda la lista de usuarios
			.map(uid => { let usuario = this.props.usuarios[uid];

				// Fecha de hoy sin horas
				let hoy = new Date(); hoy.setHours(0, 0, 0, 0);

				// Cogemos el mes y el día del cumpleaños (este dato es un String de tipo "mm-dd")
				let mes = usuario.cumple.split("-")[0];
				let dia = usuario.cumple.split("-")[1];

				// Convertimos el cumpleaños a fecha suponiendo que el cumpleaños no ha pasado aún este año
				let fecha = new Date(hoy.getFullYear(), mes-1, dia);

				// Calculamos la diferencia en días
				let diferencia = Math.ceil((fecha - hoy) / (1000 * 60 * 60 * 24)); 

				// Si la diferencia es negativa, es que ya ha pasado el cumpleaños este año, calculamos el siguiente
				if(diferencia<0){
					fecha = new Date(hoy.getFullYear()+1, mes-1, dia);
					diferencia = Math.ceil((fecha - hoy) / (1000 * 60 * 60 * 24));
				}

				// Metemos el usuario en el Array incluyendo el número de días que faltan para su cumpleaños
				usuario.faltan = diferencia;

				// Ya podemos devolver el usuario con el número de días que faltan para su cumpleaños
				return usuario;

			})
			// Ordenamos por el número de días que faltan
			.sort(function(a,b){return a.faltan-b.faltan;})
			// Nos quedamos los tres primeros solamente
			.slice(0,3);
			
		}

        // Inicializamos el State
		this.state = {
			// Cumpleaños cercanos que se mostrarán
			cumplesCercanos: cumplesCercanos
        };
        
	}


    render() {
		
        return (
    
				<div className="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-4">
					<div className="cumple componente">
						<div className="icono txtNegro"><h3><i className="fas fa-birthday-cake fa-3x"></i>Cumpleaños</h3></div>
						<div className="texto">
							<ul>

								{this.state.cumplesCercanos.map(usuario =>
									<li key={usuario.uid} className={usuario.faltan===0?"hoy":""}>
										<NavLink key={usuario.uid} exact to={"/perfiles/usuario/"+usuario.uid}>
										<div className="foto" style={{backgroundImage: "url("+(usuario.imagen || "/img/usuario.jpg")+")"}} alt="{usuario.nombre} {usuario.apellidos}"></div>
										<span>{usuario.usuario}</span>
										</NavLink>
										{usuario.faltan>0? <small>{usuario.faltan} {usuario.faltan>1?"días":"día"}</small> : <small>Hoy!</small>}
									</li>
								)}
							
							</ul>
						</div>
					</div>
				</div>
      
        );

    }

}

export default Cumples;
